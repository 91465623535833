<template>
  <div v-if="open" class="modal">
    <div class="modal_header">
      <span class="modal_title">{{ title }}</span>
      <button type="button" class="btn-close" @click="$emit('cancelEvent')">
        <img src="@/assets/icons/modal_close.svg" alt="X" />
      </button>
    </div>
    <div class="modal_subtitle">{{ subtitle }}</div>
    <div class="modal_button">
      <m-button widthButton="98px" class="cancel" @click="$emit('cancelEvent')"
        >Cancel</m-button
      >
      <m-button widthButton="98px" @click="$emit('activeEvent')">{{
        nameButtonActive
      }}</m-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "m-modal",
  props: {
    title: String,
    subtitle: String,
    open: {
      type: Boolean,
      default: false,
    },
    nameButtonActive: {
      type: String,
      default: "Delete",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 50%;
  margin-left: -150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  gap: 30px;
  width: 625px;
  background: #ffffff;
  box-shadow: 0px 4px 101px rgba(88, 91, 66, 0.14);
  border-radius: 12px;
  .btn-close {
    height: 20px;
    width: 20px;
    padding: 4px;
  }
  &_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_title {
    font-weight: 600;
    font-size: 18px;
  }
  &_subtitle {
    font-weight: 500;
    font-size: 18px;
  }
  &_button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    .cancel {
      background: #ffffff;
      border: 1px solid #cbd5e1;
      border-radius: 32px;
      &:hover {
        background: #eeeeee;
      }
    }
  }
}
</style>
