<template>
  <div class="upload-style">
    <input
      :id="`inputTag${name}`"
      type="file"
      @change="onChange($event)"
      accept="image/*"
      hidden
    />
    <label :for="`inputTag${name}`"> Выберите файл </label>
    <span :ref="`file${name}`">Файл не выбран</span>
  </div>
</template>
<script>
export default {
  name: "m-upload",
  props: {
    name: {
      type: String,
      default: "name",
    },
  },
  methods: {
    onChange(event) {
      event.target.files[0].imageType = this.name;
      this.$emit("update:modelValue", event.target.files[0]);
      console.log(this.name, event.target.files);
      this.$refs[`file${this.name}`].textContent = event.target.files[0].name;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-style {
  display: flex;
  align-items: center;

  label {
    padding: 12px 24px;
    color: white;
    width: 170px;
    height: 48px;
    background: #0f172a;
    border-radius: 32px;
    margin-right: 25px;
    cursor: pointer;
  }
  span {
    font-weight: 400;
    font-size: 20 px;
    color: #000000;
  }
}
</style>
