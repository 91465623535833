<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Currency</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Currency</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="createCurrency"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute, activeCheckbox)"
          :data="list"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
  </div>
  <m-modal
    :open="showModalDelete"
    :title="modelTitle"
    :subtitle="modelSubtitle"
    :nameButtonActive="nameButtonActive"
    @cancel-event="closeModel"
    @active-event="deleteCurrency(deleteId)"
  ></m-modal>
</template>
<script>
import { h } from "vue";
import { NButton, NCheckbox } from "naive-ui";
export default {
  name: "CurrencyPage",
  data: () => {
    return {
      createColumns: (openModal, goToRoute, activeCheckbox) => {
        return [
          {
            title: "ID",
            key: "id",
          },
          {
            title: "Name",
            key: "nameCurrency",
            className: "textTop",
          },
          {
            title: "Code",
            key: "code",
          },
          {
            title: "Active",
            key: "isActive",
            render(row) {
              return h(NCheckbox, {
                checked: row.isActive,
                size: "large",
                onClick: () => {
                  row.isActive = !row.isActive;
                  activeCheckbox(row);
                },
              });
            },
          },
          {
            title: "Action",
            key: "actions",
            className: "textTop",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("currency-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      list: [],
    };
  },
  mounted() {
    this.fetchListCurency();
  },
  methods: {
    fetchListCurency() {
      const url = "/currency";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.list = data;
        });
    },
    createCurrency() {
      this.$router.push({ path: "/currency/create" });
    },
    deleteCurrency(deleteId) {
      const url = `/currency/${deleteId}`;
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow("success", "Currency deleted");
          this.closeModel();
          this.fetchListCurency();
        })
        .catch(() => {
          this.alertShow("error", "Currency not deleted");
          this.closeModel();
        });
    },
    activeCheckbox(obj) {
      console.log(obj);
      const url = `/currency/${obj.id}`;
      const formData = new FormData();
      if (this.imageWeb) {
        formData.append("files", this.imageWeb, "imageMain");
      }
      if (this.imageApp) {
        formData.append("files", this.imageApp, "imageLong");
      }

      for (let el in obj) {
        formData.append(el, obj[el]);
      }

      this.$api
        .put(url, formData)
        .then(() => {
          this.alertShow("success", "Currency update");
        })
        .catch(() => {
          this.alertShow("error", "Currency not update");
        });
    },
  },
};
</script>
<style lang=""></style>
