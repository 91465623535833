export default [
  {
    title: 'Dashbord',
    link: '/',
    icon: 'dashbord-icon.svg',
    type: 'button',
    permission: ''
  },
  {
    title: 'USERS',
    link: '',
    icon: '',
    type: 'title',
    permission: 'users',
    children: [
      {
        title: 'All users',
        link: '/users',
        icon: 'users-icon.svg',
        type: 'button',
        permission: 'users-user-page'
      },
      {
        title: 'Statistics',
        link: '/statistics',
        icon: 'statistics-icon.svg',
        type: 'button',
        permission: 'users-statistic-page'
      },
      {
        title: 'Roles',
        link: '/roles',
        icon: 'roles-icon.svg',
        type: 'button',
        permission: 'users-roles-page'
      }
      // {
      //   title: "Support",
      //   link: "/support",
      //   icon: "support-icon.svg",
      //   type: "button",
      //   permission: "users-support-page",
      // },
    ]
  },
  {
    title: 'MARKET',
    link: '',
    icon: '',
    type: 'title',
    permission: 'market',
    children: [
      {
        title: 'Currency',
        link: '/currency',
        icon: 'currency-icon.svg',
        type: 'button',
        permission: 'market-currency-page'
      },
      {
        title: 'Deals',
        link: '/deals',
        icon: 'deals-icon.svg',
        type: 'button',
        permission: 'market-deals-page'
      },
      {
        title: 'Transaction',
        link: '/transaction',
        icon: 'transaction-icon.svg',
        type: 'button',
        permission: 'market-transaction-page'
      }
    ]
  },
  {
    title: 'FINANCE',
    link: '',
    icon: '',
    type: 'title',
    permission: 'finance',
    children: [
      {
        title: 'Deposit',
        link: '/deposit',
        icon: 'deposit-icon.svg',
        type: 'button',
        permission: 'finance-deposit-page'
      },
      // {
      //   title: "Balance",
      //   link: "/balance",
      //   icon: "balance-icon.svg",
      //   type: "button",
      //   permission: "finance-balance",
      // },
      {
        title: 'Payment Method',
        link: '/payment-method',
        icon: 'limits-icon.svg',
        type: 'button',
        permission: 'finance-payment-methods-page'
      },
      {
        title: 'Fee',
        link: '/fee',
        icon: 'fee-icon.svg',
        type: 'button',
        permission: 'finance-fees-page'
      },
      {
        title: 'Withdrawal',
        link: '/withdrawal',
        icon: 'withdrawal-icon.svg',
        type: 'button',
        permission: 'finance-withdrawals-page'
      }
    ]
  },
  {
    title: 'OTHER',
    link: '',
    icon: '',
    type: 'title',
    permission: 'other',
    children: [
      {
        title: 'Content',
        link: '',
        icon: 'content-icon.svg',
        type: 'dropbox',
        permission: 'other-content',
        menu_dropbox: [
          {
            title: 'Blog',
            link: '/content/blog',
            ref: 'content',
            icon: '',
            type: 'button',
            permission: 'other-content-blog-page'
          },
          {
            title: 'Categories',
            link: '/content/categories',
            ref: 'content',
            icon: '',
            type: 'button',
            permission: 'other-content-categories-page'
          },
          {
            title: 'Static pages',
            link: '/content/static-pages',
            ref: 'content',
            icon: '',
            type: 'button',
            permission: 'other-content-static-pages-page'
          }
        ]
      },
      {
        title: 'FAQ',
        link: '',
        icon: 'faq-icon.svg',
        type: 'dropbox',
        permission: 'other-faq',
        menu_dropbox: [
          {
            title: 'Categories',
            link: '/faq/categories',
            ref: 'faq',
            icon: '',
            type: 'button',
            permission: 'other-faq-categories-page'
          },
          {
            title: 'Questions',
            link: '/faq/questions',
            ref: 'faq',
            icon: '',
            type: 'button',
            permission: 'other-faq-questions-page'
          }
        ]
      }
    ]
  },
  {
    title: 'Languages',
    link: '/languages',
    icon: 'languages-icon.svg',
    type: 'button',
    permission: 'languages-page'
  }
]
