export const alert = {
  data: () => {
    return {
      alert: false,
      alertTitle: "",
      alertType: "",
      alertText: "",
    };
  },
  methods: {
    closeAlert() {
      this.alert = false;
      this.alertTitle = "";
      this.alertType = "";
      this.alertText = "";
    },
    alertShow(type, text) {
      if (type === "success") {
        this.alert = true;
        this.alertTitle = "Success";
        this.alertType = "success";
        this.alertText = text;
      } else if (type === "error") {
        this.alert = true;
        this.alertTitle = "Error";
        this.alertType = "error";
        this.alertText = text;
      }
    },
  },
};
