<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Blog</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/content/blog">Blog</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <n-tabs type="line" animated size="large">
        <n-tab-pane name="general" tab="General">
          <div class="tabs-content">
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Main photo</span>
              </div>
              <div class="width100">
                <m-upload name="imageMain" v-model="imageMain"> </m-upload>
              </div>
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Feed long photo</span>
              </div>
              <div class="width100">
                <m-upload name="imageLong" v-model="imageLong"> </m-upload>
              </div>
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Category</span>
              </div>
              <div class="width100">
                <n-space vertical>
                  <n-select
                    v-model:value="form.articleThemeId"
                    :options="options"
                  />
                </n-space>
              </div>
            </div>

            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Time reading (minutes)</span>
              </div>
              <m-input
                type="number"
                v-model="form.timeToRead"
                placeholder="Time reading (minutes)"
              />
            </div>

            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Is Publish</span>
              </div>
              <div class="width100">
                <n-space>
                  <n-switch v-model:value="form.isPublish" />
                </n-space>
              </div>
            </div>
          </div>
        </n-tab-pane>
        <n-tab-pane name="content" tab="Content">
          <div class="tabs-content">
            <!-- Header -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Header</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.header"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
            <!-- Slug -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Slug</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.slug"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
            <!-- Preview -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Preview</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.preview"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
            <!-- Content -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Content</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <QuillEditor
                      contentType="html"
                      theme="snow"
                      v-model:content="form.content"
                    />
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <QuillEditor theme="snow" v-model:content="dataProperty" />
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <QuillEditor theme="snow" v-model:content="dataProperty" />
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
            <!-- Photo main alt -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Image main alt</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.imageMainAlt"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>

            <!-- Photo long alt -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Image long alt</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.imageLongAlt"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
            <!-- Meta title -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Meta title</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.metaTitle"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>

            <!-- Meta description -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Meta description</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.metaDescription"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>

            <!-- Meta keywords -->
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Meta keywords</span>
              </div>
              <div class="width100">
                <n-tabs type="card">
                  <n-tab-pane name="en" tab="Eng">
                    <m-input v-model="form.metaKeywords"></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="ru" tab="Rus">
                    <m-input></m-input>
                  </n-tab-pane>
                  <n-tab-pane name="uk" tab="Ukr">
                    <m-input></m-input>
                  </n-tab-pane>
                </n-tabs>
              </div>
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="createArticle">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "BlogCreatePage",
  components: {
    QuillEditor,
  },
  data: () => {
    return {
      dataProperty: "",
      imageMain: "",
      imageLong: "",
      form: {
        timeToRead: "",
        articleThemeId: "",
        isPublish: true,

        header: "",
        slug: "",
        preview: "",
        content: "",
        imageMainAlt: "",
        imageLongAlt: "",
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
      },
      options: [],
    };
  },
  mounted() {
    this.fetchListCategorys();
  },
  methods: {
    createArticle() {
      const url = "/articles";
      const formData = new FormData();
      if (this.imageMain) {
        formData.append("files", this.imageMain, "imageMain");
      }
      if (this.imageLong) {
        formData.append("files", this.imageLong, "imageLong");
      }

      for (let el in this.form) {
        formData.append(el, this.form[el]);
      }

      this.$api
        .post(url, formData)
        .then(() => {
          this.alertShow("success", "Article created");
        })
        .catch(() => {
          this.alertShow("error", "Article not created");
        });
    },
    fetchListCategorys() {
      const url = "/article-themes";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          console.log(data);
          this.options = data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang=""></style>
