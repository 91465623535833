<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Payment Methods</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/payment-method"
          >Payment Methods</router-link
        >/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Payment method</span>
        </div>
        <m-input placeholder="Payment method name" v-model="form.name" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Payment method parent</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select
              v-model:value="form.parentId"
              :options="listPaymentMethod"
              placeholder="Payment method parents"
            />
          </n-space>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Description buy</span>
        </div>
        <div class="width100">
          <textarea
            class="textareaDesc"
            v-model="form.descriptionBuy"
            placeholder="Description buy"
          ></textarea>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Description sell</span>
        </div>
        <div class="width100">
          <textarea
            class="textareaDesc"
            v-model="form.descriptionSell"
            placeholder="Description sell"
          ></textarea>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Transaction time min</span>
        </div>
        <div class="style-select">
          <div class="time-input">
            <label for="time-day">Days</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.days"
                button-placement="both"
                min="0"
                max="10"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Hours</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.hours"
                button-placement="both"
                min="0"
                max="23"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Minutes</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.minutes"
                button-placement="both"
                min="0"
                max="59"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Transaction time max</span>
        </div>
        <div class="style-select">
          <div class="time-input">
            <label for="time-day">Days</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.days"
                button-placement="both"
                min="0"
                max="10"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Hours</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.hours"
                button-placement="both"
                min="0"
                max="23"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Minutes</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.minutes"
                button-placement="both"
                min="0"
                max="59"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Minimum transaction cost limit</span>
        </div>
        <m-input
          type="number"
          placeholder="Minimum transaction cost limit"
          v-model="form.transactionLimitCost"
        />
      </div>
      <div class="row">
        <div class="block-input">
          <div class="col-title">
            <span class="subtitle">Fee BTC</span>
          </div>
          <m-input
              type="number"
              placeholder="Fee BTC"
              v-model="feeBtc"
          />
        </div>
        <div class="block-input">
          <div class="col-title">
            <span class="subtitle">Fee USDT</span>
          </div>
          <m-input
              type="number"
              placeholder="Fee USDT"
              v-model="feeUsdt"
          />
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isActive" />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle"> Is showing as tag </span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isShowingAsTag" />
          </n-space>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Icon white theme</span>
        </div>
        <div class="width100">
          <m-upload name="whiteThemeImage" v-model="imageWeb"> </m-upload>
        </div>

      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Icon black theme</span>
        </div>
        <div class="width100">
          <m-upload name="blackThemeImage" v-model="imageWeb2"> </m-upload>
        </div>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="createPaymentMethod">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LimitsCreatePage",
  data: () => {
    return {
      listParents: [],
      imageWeb: {},
      imageWeb2: {},
      disabledInputNumber: false,
      minTime: { days: 0, hours: 0, minutes: 0 },
      maxTime: { days: 0, hours: 0, minutes: 0 },
      feeBtc: 0,
      feeUsdt: 0,
      form: {
        name: "",
        parentId: null,
        transactionLimitTimeMin: 0,
        transactionLimitTimeMax: 0,
        transactionLimitCost: 10,
        descriptionBuy: "",
        descriptionSell: "",
        isActive: false,
        isShowingAsTag: false,
      },
      listPaymentMethod: [
        {
          label: "Bank transfer",
          value: "1",
        },
        {
          label: "Online wallets",
          value: "2",
        },
        {
          label: "Credit cards",
          value: "3",
        },
        {
          label: "Gift card",
          value: "4",
        },
        {
          label: "Digital currencies",
          value: "5",
        },
        {
          label: "Cash payment",
          value: "6",
        },
        {
          label: "Goods & Services",
          value: "7",
        },
      ],
    };
  },
  computed: {},
  watch: {
    "form.parentId"(event) {
      const parentMethod = this.listParents.find((el) => el.id === event);
      this.getDateFromTimestamp(parentMethod.transactionLimitTimeMax, "max");
      this.getDateFromTimestamp(parentMethod.transactionLimitTimeMin, "min");

      this.disabledInputNumber = true;
    },
  },
  mounted() {
    this.fetchListParents();
  },
  methods: {
    createPaymentMethod() {
      this.form.transactionLimitTimeMin = this.getTimestampWithDate(
        this.minTime
      );
      this.form.transactionLimitTimeMax = this.getTimestampWithDate(
        this.maxTime
      );
      const url = `/payment-methods`;
      const formData = new FormData();
      if (this.imageWeb) {
        formData.append("images", [this.imageWeb, this.imageWeb2]);
      }

      for (let el in this.form) {
        formData.append(el, this.form[el]);
      }
      formData.append('paymentMethodFees', JSON.stringify([
        {
          coin: 'BTC',
          fee: this.feeBtc
        },
        {
          coin: 'USDT',
          fee: this.feeUsdt
        }
      ]))

      this.$api
        .post(url, formData)
        .then(() => {
          this.alertShow("success", "Payment method create");
        })
        .catch(() => {
          this.alertShow("error", "Payment method not create");
        });
    },
    getTimestampWithDate(timeObj) {
      const { days, hours, minutes } = timeObj;
      return (days * 24 * 3600 + hours * 3600 + minutes * 60) * 1000;
    },
    fetchListParents() {
      const url = `/payment-methods/all-parents`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listParents = data;
          this.listPaymentMethod = data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });
        });
    },
    getDateFromTimestamp(timestamp, fold) {
      let seconds = Math.floor(timestamp / 1000);
      let minutes = Math.floor((seconds % 3600) / 60);
      let hours = Math.floor((seconds % (3600 * 24)) / 3600);
      let days = Math.floor(seconds / (3600 * 24));
      if (fold === "max") {
        this.maxTime.days = days;
        this.maxTime.hours = hours;
        this.maxTime.minutes = minutes;
      }

      if (fold === "min") {
        this.minTime.days = days;
        this.minTime.hours = hours;
        this.minTime.minutes = minutes;
      }
    },
  },
};
</script>
<style lang="scss">
.title {
  font-family: "Averta CY", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  align-self: stretch;
  flex-grow: 0;
}
.time-input {
  margin-right: 10px;
  width: 180px;
  .n-input .n-input__input,
  .n-input .n-input__textarea {
    display: flex;
    justify-content: flex-end;
  }
  .n-input .n-input__input-el,
  .n-input .n-input__textarea-el {
    width: 60%;
  }
}
.style-select {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .selec {
    width: 115px;
  }
  .n-input {
    border-radius: 12px;
  }
}
.textareaDesc {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 14px;
  gap: 10px;
  width: 100%;

  background: #ffffff;

  border: 1px solid #e2e8f0;
  border-radius: 12px;
  &::placeholder {
    color: rgba(194, 194, 194, 1);
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  gap: 14px;
}
</style>
