<template>
  <n-message-provider></n-message-provider>
</template>
<script>
import { io } from 'socket.io-client'
import { useStore } from 'vuex'
import { createDiscreteApi } from 'naive-ui'
import { defineComponent } from 'vue'
const { message } = createDiscreteApi(['message'])
export default defineComponent({
  name: 'WebSocketExample',
  setup() {
    const store = useStore()

    if (store.getters['UsersStore/getIsAuthenticated']) {
      const socket = io(process.env.VUE_APP_API_URL_WEBSOCKET, {
        extraHeaders: {
          Authorization: `${localStorage.getItem('token')}`
        }
      })

      socket.on('connect', () => {
        console.log(socket.id)
      })

      socket.on('disconnect', () => {
        console.log(socket.id)
      })

      socket.addEventListener('msgToClient', (event) => {
        console.log('msgToClient ld', event)
        if (event.eventName !== 'exchange-rates') {
          store.commit('SocketEventStore/setEvent', event)
          message.info('Сообщение в сделке:' + event.data.dealId)
        }
      })
    }
  }
})
</script>
<style scoped></style>
