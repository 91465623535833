<template>
  <div class="cards-grow">
    <div class="card" :style="{ background: colorBord }">
      <div class="card-count">
        <div class="card-title">{{ countNumber }}</div>
        <div class="card-subtitle">{{ countTitle }}</div>
      </div>
      <div class="card-image">
        <img
          :src="imgUrl"
          alt="img"
          :style="{ filter: filterImg }"
          style="width: 160px"
        />
      </div>
    </div>
    <button
      class="card-button"
      @click="buttonClick"
      :style="{ background: colorButton }"
    >
      <span style="font-size: 20px">
        {{ titleButton }}
      </span>
      <img
        style="width: 20px"
        :src="require(`@/assets/icons/arrow-button-card.svg`)"
        alt="img"
      />
    </button>
  </div>
</template>
<script>
export default {
  name: "CardComponent",
  props: {
    countTitle: {
      type: String,
      default: "Title",
    },
    countNumber: {
      type: Number,
      default: 333,
    },
    imgUrl: {
      type: String,
    },
    filterImg: {
      type: String,
    },
    colorBord: {
      type: String,
      default: "red",
    },
    colorButton: {
      type: String,
      default: "blue",
    },
    titleButton: {
      type: String,
      default: "More info",
    },
  },
  methods: {
    buttonClick() {
      console.log("Click");
    },
  },
};
</script>
<style lang="scss">
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 14px;
  height: 20vh;
  gap: 20px;
  border-radius: 12px 12px 0px 0px;
  color: #ffffff;
}
.card-count {
  margin-bottom: 40px;
}
.card-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 16px;
}
.card-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}
.card-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 14px;
  border-radius: 0px 0px 12px 12px;
  color: #ffffff;
  gap: 11px;
}
.cards-grow {
  flex-grow: 1;
}
</style>
