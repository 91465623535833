<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Blog</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Blog</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="create"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute)"
          :data="list"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
  </div>
  <m-modal
    :open="showModalDelete"
    :title="modelTitle"
    :subtitle="modelSubtitle"
    :nameButtonActive="nameButtonActive"
    @cancel-event="closeModel"
    @active-event="deleteBlog(deleteId)"
  ></m-modal>
</template>
<script>
import { h } from "vue";
import { NButton, NTag } from "naive-ui";
export default {
  name: "BlogPage",
  data: () => {
    return {
      createColumns: (openModal, goToRoute) => {
        return [
          {
            title: "Id",
            key: "id",
          },
          {
            title: "Main Photho",
            key: "imageMainUrl",
            width: "130px",
            render(row) {
              if (row.imageMainUrl) {
                return h("img", {
                  src: row.imageMainUrl,
                  alt: "Not image",
                });
              } else {
                return "Not image";
              }
            },
          },
          {
            title: "Header",
            key: "header",
          },
          {
            title: "Slug",
            key: "slug",
          },
          {
            title: "Category",
            key: "articleTheme.title",
          },
          {
            title: "Time reading (minutes)",
            key: "timeToRead",
            width: "130px",
          },
          {
            title: "Date created",
            key: "createdAt",
            width: "130px",
            render(row) {
              const date = new Date(row.createdAt);
              const year = date.getFullYear();
              const day = date.getDate();
              const correctDay = day < 10 ? `0${day}` : day;
              const mount = date.getMonth();
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const seconds = date.getSeconds();
              const correctMount = mount < 10 ? `0${mount}` : mount;
              return `${correctDay}.${correctMount}.${year} ${hours}:${minutes}:${seconds}`;
            },
          },
          {
            title: "Is Active",
            key: "isPublish",
            render(row) {
              const status = row.isPublish;
              let colorBG = "";
              let colorText = "";
              if (status) {
                colorBG = "#EDF6F2";
                colorText = "#4DA97D";
              } else {
                colorBG = "#FEEFF2";
                colorText = "#C04949";
              }
              return h(
                NTag,
                {
                  style: {
                    padding: "16px",
                    color: colorText,
                    background: colorBG,
                    fontSize: "14px",
                  },
                  round: true,
                  bordered: false,
                },
                {
                  default: () => {
                    return `${status}`;
                  },
                }
              );
            },
          },
          {
            title: "Action",
            key: "actions",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("blog-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      list: [],
    };
  },
  mounted() {
    this.fetchListArticles();
  },
  methods: {
    create() {
      this.$router.push({ path: "blog/create" });
    },
    fetchListArticles() {
      const url = "/articles";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.list = data;
        });
    },
    async deleteBlog(deleteId) {
      const url = `/articles/${deleteId}`;
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow("success", "Article deleted");
          this.closeModel();
          this.fetchListArticles();
        })
        .catch(() => {
          this.alertShow("error", "Article not deleted");
          this.closeModel();
        });
    },
  },
};
</script>
<style lang=""></style>
