<template>
  <div class="container">
    <div class="alert" v-if="alert">
      <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
        {{ alertText }}
      </n-alert>
    </div>
    <div class="header">
      <div class="title">Questions</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/faq/questions">Questions</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <!-- Question -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Question</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <m-input v-model="question"></m-input>
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <m-input></m-input>
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <m-input></m-input>
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <!-- Answer -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Answer</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <QuillEditor
                  contentType="html"
                  toolbar="minimal"
                  theme="snow"
                  v-model:content="answer" />
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <QuillEditor theme="snow" />
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <QuillEditor theme="snow" />
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <!-- Category -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Category</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select v-model:value="categoryId" label-field="title" valueField="id" :options="options" />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle"> Is showing as tag </span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="isPublish" />
          </n-space>
        </div>
      </div>
      <div class="content-block__button">
        <m-button @click="$router.push('/faq/questions')" widthButton="101px" class="btn_cancel">
          <span>Cancel</span>
        </m-button>
        <m-button @click="postQuestion" widthButton="101px">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "FAQQuestionsCreatePage",
  components: {
    QuillEditor,
  },
  data: () => {
    return {
      options: [],
      isPublish: false,
      answer: "",
      question: '',
      categoryId: ''
    };
  },
  mounted() {
    this.$api
        .get('/faq-category')
        .then((data) => {
          this.options = data.data.filter(el => el.isPublish)
        });
  },
  methods: {
    postQuestion() {
      this.$api.post('/faq', {
        question: this.question,
        answer: this.answer === '<p><br></p>' ? '' : this.answer,
        isPublish: this.isPublish,
        order: 0,
        categoryId: this.categoryId
      }).then((ctx) => {
        console.log(ctx)
        this.alertShow('success', 'Success create question')
        this.question = '';
        this.answer = '';
        this.isPublish = false;
        this.categoryId = null
      }).catch(err => {
        console.log(err.response)
        this.alertShow('error', err.response.data.message)
      })
    }
  }
};
</script>
<style lang=""></style>
