<template>
  <div v-if="listOwnerPermissions" :key="keyMenu">
    <div :class="{ notShowMenu: isActive }" ref="sidebar">
      {{ category }}
      <div class="sidebar" :class="isOpened ? 'open' : ''">
        <div class="logo-details">
          <img class="logo-image" src="@/assets/images/Logo-menu.svg" alt="menu-logo" />
          <span class="logo-text">Fintopio</span>
<!--          <span class="logo-title show" :class="{ hide: !isOpened }">Admin</span>-->
          <div class="circle-btn" @click="isOpened = !isOpened">
            <img :class="{ right: !isOpened }" src="@/assets/icons/Icons.svg" alt="" />
          </div>
        </div>
        <hr class="line" />
        <div class="menu" id="my-scroll">
          <div :class="{ 'block-menu': showButton(elem) }" v-for="elem in listMenu" :key="elem.title">
            <button
              v-if="elem.type === 'button' && showButton(elem)"
              class="btn-block"
              :ref="getRefSring(elem.link)"
              :id="getRefSring(elem.link)"
              @click="getClassActive('/')"
            >
              <router-link class="btn-link" :to="elem.link">
                <div class="img-svg">
                  <img :src="require(`@/assets/icons/${elem.icon}`)" :alt="elem.icon" />
                </div>
                <span class="show" :class="{ hide: !isOpened }">{{ elem.title }}</span></router-link
              >
            </button>
            <div v-if="elem.type === 'title' && showButton(elem)">
              <span class="title-menu show" :class="{ hide: !isOpened }">{{ elem.title }}</span>
              <div v-for="btn in elem.children" :key="btn.title">
                <div v-if="showButton(btn)">
                  <button
                    class="btn-block"
                    v-if="btn.type === 'button'"
                    :ref="getRefSring(btn.link)"
                    :id="getRefSring(btn.link)"
                    @click="getClassActive"
                  >
                    <router-link class="btn-link" :to="btn.link">
                      <div class="img-svg">
                        <img :src="require(`@/assets/icons/${btn.icon}`)" :alt="btn.icon" />
                      </div>
                      <span class="show" :class="{ hide: !isOpened }">{{ btn.title }}</span></router-link
                    >
                  </button>

                  <div class="btn-collaps" v-if="btn.type === 'dropbox'" @click="getClassActive(btn.title)">
                    <Collapse :icon="btn.icon" :name="btn.title" :menuDropbox="btn.menu_dropbox" :isOpened="isOpened" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn-block" @click="logout">
            <div class="btn-link">
              <div class="img-svg">
                <img src="@/assets/icons/logout-icon.svg" alt="logout-icon.svg" />
              </div>
              <span class="show" :class="{ hide: !isOpened }">Logout</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list from './listMenu'
import Collapse from './collapse.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'SidebarMenuAkahon',
  components: {
    Collapse
  },
  props: {
    isMenuOpen: {
      type: Boolean,
      default: true
    },
    isPaddingLeft: {
      type: Boolean,
      default: true
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: '264px'
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: '96px'
    }
  },
  data() {
    return {
      isOpened: false,
      isActive: true,
      listMenu: [],
      keyMenu: 1
    }
  },
  async mounted() {
    this.isOpened = this.isMenuOpen
    this.listMenu = list
    if (!this.listOwnerPermissions.length) {
      await this.$store.dispatch('UsersStore/fetchUserRoles')
    }
    this.isActiveSideBar()
  },
  updated() {
    const routeName = document.location.pathname.split('/')[1]
    const name = routeName ? routeName : '/'
    this.getClassActive(name)
  },
  computed: {
    ...mapGetters({ isAuthenticated: 'UsersStore/getIsAuthenticated' }),
    ...mapGetters({
      listOwnerPermissions: 'UsersStore/getListOwnerPermissions'
    }),

    category() {
      this.$route
      this.isActiveSideBar()
      return ' '
    }
  },
  watch: {
    isOpened() {
      this.isActiveSideBar()
    },
    isAuthenticated() {
      this.isActiveSideBar()
    },
    listOwnerPermissions() {
      this.keyMenu += 1
    }
  },
  methods: {
    getClassActive(name) {
      this.clearClassActive()
      if (name !== 'login') {
        if (typeof name === 'string' && name) {
          const el = document.getElementById(name.toLowerCase())
          if (el) {
            el.classList.add('active')
          }
        } else {
          this.$refs[this.$route.name][0].classList.add('active')
        }
      }
    },
    getRefSring(str) {
      if (str.split('/')[1]) {
        return str.split('/')[1]
      } else {
        return str.toLowerCase()
      }
    },
    clearClassActive() {
      const listClassActive = document.getElementsByClassName('active')
      if (listClassActive.length) {
        for (let element of listClassActive) {
          element.classList.remove('active')
        }
      }
    },
    isActiveSideBar() {
      if (localStorage.getItem('is_expanded')) {
        this.isActive = false
        window.document.body.style.paddingLeft =
          this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
      } else {
        this.isActive = true
        window.document.body.style.paddingLeft = '0'
      }
    },
    async logout() {
      await this.$store.dispatch('AuthStore/logout')
      this.$store.commit('UsersStore/isAuthenticated', false)
      this.isActiveSideBar()
      this.$router.push('/login')
    },
    showButton(elem) {
      if (!elem.permission) return true
      if (elem.type === 'title') {
        return this.listOwnerPermissions.find((element) => {
          const listRote = element.split('-')
          return `${listRote[0]}` === elem.permission
        })
          ? true
          : false
      }
      if (elem.type === 'dropbox') {
        return this.listOwnerPermissions.find((element) => {
          const listRote = element.split('-')
          return `${listRote[0]}-${listRote[1]}` === elem.permission
        })
          ? true
          : false
      }
      return this.listOwnerPermissions.find((element) => {
        return element === elem.permission
      })
        ? true
        : false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style.scss';
body {
  transition: all 0.5s ease;
}
.notShowMenu {
  display: none !important;
}
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  width: 96px;
  background: $bg-color-menu;
  z-index: 99;
  transition: all 0.5s ease;
  color: #ffffff;
  .logo-details {
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center;
    padding: 0px;
    margin: 15px 0 12px 20px;
    .logo-image {
      margin-right: 7px;
    }
    .logo-text {
      color: white;
      font-size: 16px;
    }
    .logo-title {
      font-weight: 600;
      line-height: 24px;
      margin-right: 29px;
      padding-top: 5px;
    }
    .circle-btn {
      box-sizing: border-box;
      position: absolute;
      width: 30px;
      height: 30px;
      right: -15px;
      top: 0;
      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 100px;
      z-index: 99;
      transform: rotate(0deg);
      & .right {
        transition: all 0.3s ease;
        transform: rotate(180deg);
      }
    }
  }
  .line {
    color: #e2e8f0;
    width: 100%;
  }
}
.sidebar.open {
  width: 264px;
}
.menu {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  padding: 16px;

  .block-menu {
    padding: 20px 0 20px 5px;

    .title-menu {
      text-transform: uppercase;
    }
  }

  .btn-block {
    height: 50px;
    width: 100%;
    cursor: pointer;
    color: #ffffff;

    &:hover {
      border-right: 3px solid $color-active-btn;
      .btn-link {
        color: $color-active-btn;
      }
      .img-svg {
        filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg) brightness(100%) contrast(93%);
      }
    }
  }

  .btn-link {
    display: flex;
    align-items: center;
    width: 100%;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
  }
  .img-svg {
    display: flex;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(29deg) brightness(107%) contrast(108%);
  }
}
.active {
  border-right: 3px solid $color-active-btn;
  .btn-link {
    color: $color-active-btn !important;
  }
  .img-svg {
    filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg) brightness(100%) contrast(93%) !important;
  }
}
.my-scroll-active {
  overflow-y: auto;
}
#my-scroll {
  overflow-y: auto;
  // height: calc(100% - 60px);
}
#my-scroll::-webkit-scrollbar {
  display: none;
  /* background-color: rgba(255, 255, 255, 0.2);
    width: 10px;
    border-radius:5px  */
}
/* #my-scroll::-webkit-scrollbar-thumb{
    background-color: red;
    border-radius:5px
  }
  #my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
    display:none;
  }
  #my-scroll::-webkit-scrollbar-button:vertical:end:increment{
    display:none;
  } */
.show {
  transition: all 0.5s ease;
  font-size: 16px;
}
.hide {
  transition: all 0.5s ease;
  font-size: 0 !important;
  width: 0 !important;
}
</style>
