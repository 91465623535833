<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Payment Methods</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Payment Method</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="createPaymentMethod"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="
            createColumns(
              openModal,
              goToRoute,
              activeCheckboxPM,
              activeCheckboxPMTag
            )
          "
          :data="list"
          :single-line="false"
        />
        <n-pagination
          class="top20"
          :item-count="total"
          size="large"
          :default-page-size="paramsFilter.limit"
          @update:page="updatePage"
        >
        </n-pagination>
      </div>
    </div>
  </div>
  <m-modal
    :open="showModalDelete"
    :title="modelTitle"
    :subtitle="modelSubtitle"
    :nameButtonActive="nameButtonActive"
    @cancel-event="closeModel"
    @active-event="deletePaymentMethod(deleteId)"
  ></m-modal>
</template>
<script>
import { h } from "vue";
import {NButton, NCheckbox, NTag} from "naive-ui";
export default {
  name: "CurrencyPage",
  data: () => {
    return {
      createColumns: (
        openModal,
        goToRoute,
        activeCheckboxPM,
        activeCheckboxPMTag
      ) => {
        return [
          {
            title: "ID",
            key: "id",
          },
          {
            title: "Payment method",
            key: "name",
          },
          {
            title: "Parent",
            key: "parentId",
          },
          {
            title: "Fee BTC",
            key: "paymentMethodFees",
            render(row) {
              const fee =
                  row.parentId ?
                      row.paymentMethodFees.filter(el => el.coin === 'BTC')[0] ?
                          row.paymentMethodFees.filter(el => el.coin === 'BTC')[0].fee :
                          'None' :
                      'None'
              let colorBG = '#EDF6F2'
              let colorText = '#4DA97D'
              return h(
                  NTag,
                  {
                    style: {
                      padding: '16px',
                      color: colorText,
                      background: colorBG,
                      fontSize: '14px'
                    },
                    round: true,
                    bordered: false
                  },
                  {
                    default: () => {
                      return fee
                    }
                  }
              )
            }
          },
          {
            title: "Fee USDT",
            key: "title",
            render(row) {
              const fee =
                  row.parentId ?
                  row.paymentMethodFees.filter(el => el.coin === 'USDT')[0] ?
                      row.paymentMethodFees.filter(el => el.coin === 'USDT')[0].fee :
                      'None' :
                      'None'
              let colorBG = '#EDF6F2'
              let colorText = '#4DA97D'
              return h(
                  NTag,
                  {
                    style: {
                      padding: '16px',
                      color: colorText,
                      background: colorBG,
                      fontSize: '14px'
                    },
                    round: true,
                    bordered: false
                  },
                  {
                    default: () => {
                      return fee
                    }
                  }
              )
            }
          },
          {
            title: "Time min",
            key: "transactionLimitTimeMin",
            render(row) {
              let seconds = Math.floor(row.transactionLimitTimeMin / 1000);
              let minutes = Math.floor((seconds % 3600) / 60);
              let hours = Math.floor((seconds % (3600 * 24)) / 3600);
              let days = Math.floor(seconds / (3600 * 24));

              let display = days > 0 ? `Days : ${days} ` : ``;
              display =
                hours > 0 ? display + `Hours : ${hours} ` : display + ``;
              display =
                minutes > 0 ? display + `Minutes : ${minutes} ` : display + ``;

              return display;
            },
          },
          {
            title: "Time max",
            key: "transactionLimitTimeMax",
            render(row) {
              let seconds = Math.floor(row.transactionLimitTimeMax / 1000);
              let minutes = Math.floor((seconds % 3600) / 60);
              let hours = Math.floor((seconds % (3600 * 24)) / 3600);
              let days = Math.floor(seconds / (3600 * 24));

              let display = days > 0 ? `Days : ${days} ` : ``;
              display =
                hours > 0 ? display + `Hours : ${hours} ` : display + ``;
              display =
                minutes > 0 ? display + `Minutes : ${minutes} ` : display + ``;

              return display;
            },
          },
          {
            title: "Limit cost",
            key: "transactionLimitCost",
          },
          {
            title: "Active",
            key: "isActive",
            render(row) {
              return h(NCheckbox, {
                checked: row.isActive,
                size: "large",
                onClick: () => {
                  row.isActive = !row.isActive;
                  activeCheckboxPM(row);
                },
              });
            },
          },
          {
            title: "Show Tag",
            key: "isShowingAsTag",
            render(row) {
              return h(NCheckbox, {
                checked: row.isShowingAsTag,
                size: "large",
                onClick: () => {
                  row.isShowingAsTag = !row.isShowingAsTag;
                  activeCheckboxPMTag(row);
                },
              });
            },
          },
          {
            title: "Action",
            key: "actions",
            className: "textTop",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("payment-method-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      list: [],
      paramsFilter: {
        page: 1,
        limit: 10,
      },
      total: null,
    };
  },
  mounted() {
    this.fetchListPaymentMethods();
  },
  methods: {
    createPaymentMethod() {
      this.$router.push({ name: "payment-method-create" });
    },
    deletePaymentMethod(deleteId) {
      const url = `/payment-methods/${deleteId}`;
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow("success", "Payment method deleted");
          this.closeModel();
          this.fetchListPaymentMethods();
        })
        .catch(() => {
          this.alertShow("error", "Payment method not deleted");
          this.closeModel();
        });
    },
    fetchListPaymentMethods() {
      const url = "/payment-methods";
      this.$api
        .get(url, {
          params: this.paramsFilter,
        })
        .then((res) => res.data)
        .then((data) => {
          this.list = data.list;
          this.total = data.total;
        });
    },
    updatePage(page) {
      this.paramsFilter.page = page;
      this.fetchListPaymentMethods();
    },
    activeCheckboxPM(obj) {
      const url = `/payment-methods/${obj.id}`;
      this.$api
        .put(url, obj)
        .then(() => {
          this.alertShow("success", "Paymment method update");
        })
        .catch(() => {
          this.alertShow("error", "Paymment method update");
        });
    },
    activeCheckboxPMTag(obj) {
      const url = `/payment-methods/${obj.id}`;
      this.$api
        .put(url, obj)
        .then(() => {
          this.alertShow("success", "Paymment method update");
        })
        .catch(() => {
          this.alertShow("error", "Paymment method not update");
        });
    },
  },
};
</script>
<style lang=""></style>
