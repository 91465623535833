import { createApp } from "vue";
import App from "./App.vue";
import components from "@/components/UI/index";
import router from "@/router/router";
import store from "@/store/index";
import axiosInstance from "@/instance/axios.instance";
import naive from "naive-ui";
import { alert } from "@/mixins/alert";
import { cancel } from "@/mixins/cancel";
import { modal } from "@/mixins/modal";

const app = createApp(App);
app.mixin(alert);
app.mixin(cancel);
app.mixin(modal);
app.use(naive);
components.forEach((component) => {
  app.component(component.name, component);
});
store.$login = { ...axiosInstance.loginInstance };
store.$api = { ...axiosInstance.apiInstance };
router.$store = store;
app.config.globalProperties.$login = { ...axiosInstance.loginInstance };
app.config.globalProperties.$api = { ...axiosInstance.apiInstance };
app.use(store);
app.use(router);
app.mount("#app");
