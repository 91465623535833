<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Fee</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/fee">Fee</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>

    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Payment method</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select
              v-model:value="createParams.paymentMethodId"
              :options="listPaymentMethod"
            />
            <div class="error" v-if="v$.createParams.paymentMethodId.$error">
              Invalid field. Choose payment method!
            </div>
          </n-space>
        </div>
      </div>
      <!-- feeOfSell -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee of Sell</span>
        </div>
        <m-input
          placeholder="Fee of Sell"
          type="number"
          :step="0.001"
          :min="0"
          :max="100"
          v-model:modelValue="createParams.feeOfSell"
        />
      </div>
      <!-- feeOfBuy -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee of Buy</span>
        </div>
        <m-input
          placeholder="Fee of Buy"
          type="number"
          :step="0.001"
          :min="0"
          :max="100"
          v-model:modelValue="createParams.feeOfBuy"
        />
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="createParams.isActive" />
          </n-space>
        </div>
      </div>

      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updateFee">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "FeeEditPage",
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      listPaymentMethod: [
        {
          label: "Bank transfer",
          value: "Bank transfer",
        },
        {
          label: "Credit cards",
          value: "Credit cards",
        },
        {
          label: "Digital currencies",
          value: "Digital currencies",
        },
        {
          label: "Cash payment",
          value: "Cash payment",
        },
        {
          label: "Goods & Services",
          value: "Goods & Services",
        },
        {
          label: "Online wallets",
          value: "Online wallets",
        },
        {
          label: "Gift cards",
          value: "Gift cards",
        },
      ],
      createParams: {
        paymentMethodId: null,
        feeOfSell: null,
        feeOfBuy: null,
        isActive: false,
      },
    };
  },
  validations() {
    return {
      createParams: {
        paymentMethodId: { required },
      },
    };
  },
  props: {
    id: {
      type: Number,
    },
  },
  mounted() {
    this.fetchPaymentMethod();
    this.fetchFees();
  },
  methods: {
    async updateFee() {
      await this.v$.createParams.$touch();
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const url = `/fees/${this.id}`;
        this.$api
          .put(url, this.createParams)
          .then(() => {
            this.alertShow("success", "Fee update");
          })
          .catch(() => {
            this.alertShow("error", "Fee not update");
          });
      }
    },
    fetchPaymentMethod() {
      const url = `/payment-methods/all-parents`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listPaymentMethod = data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });
        });
    },
    fetchFees() {
      const url = `/fees/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.createParams = { ...data };
        });
    },
  },
};
</script>
<style lang="scss">
.error {
  color: red;
}
</style>
