<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Categories</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/content/category"
          >Categories</router-link
        >/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Title</span>
        </div>
        <m-input v-model="form.title" type="text" placeholder="Title" />
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Slug</span>
        </div>
        <m-input v-model="form.slug" type="text" placeholder="Slug" />
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Is Publish</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isPublish" />
          </n-space>
        </div>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="createCategory()">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentCategoriesCreatePage",
  data: () => {
    return {
      form: {
        title: "",
        slug: "",
        isPublish: false,
      },
    };
  },
  methods: {
    createCategory() {
      const url = "/article-themes";
      this.$api
        .post(url, this.form)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang=""></style>
