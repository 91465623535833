import router from '@/router/router'

export const authGuard = async (to, from, next) => {
  if (router.$store.getters['UsersStore/getIsAuthenticated']) {
    const locStExpires = localStorage.getItem('expires_at') * 1000
    const dateNow = new Date().getTime()
    let expiresTime
    if (locStExpires > 0 && dateNow < locStExpires) {
      expiresTime = locStExpires - 15 * 60 * 1000
    }
    if (dateNow > expiresTime) {
      router.$store.dispatch('AuthStore/getAccessToken')
    }
    next()
  } else {
    await router.$store.dispatch('AuthStore/logout')
    next({ name: 'login' })
  }
}

export const accessGuard = async (to, from, next) => {
  await await router.$store.dispatch('UsersStore/fetchUserRoles')
  const listOwnerPermissions = await JSON.parse(window.localStorage.getItem('permissions'))

  let storeListOwnerPermissions = await router.$store.getters['UsersStore/getListOwnerPermissions']
  let userPermissions = listOwnerPermissions || storeListOwnerPermissions

  if (!userPermissions) {
    next({ name: 'dashbord' })
  }

  if (userPermissions.find((el) => el === to.meta.permission)) {
    next()
  } else {
    next({ name: 'dashbord' })
  }
}
