<template>
  <div class="block-m-input">
    <input
      :id="id"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :step="step"
      :min="min"
      :max="max"
      @input="$emit('update:modelValue', getValue($event.target.value))"
      class="m-input"
    />
    <label v-if="isError" :for="id" class="error">
      {{ textError }}
    </label>
  </div>
</template>
<script>
export default {
  name: "m-input",
  data: () => {
    return {};
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Enter text",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    textError: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "id",
    },
    modelValue: {
      type: [Number, String],
    },
    step: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getValue(event) {
      if (this.type === "number") {
        return Number(event);
      } else {
        return event;
      }
    },
  },
};
</script>
<style lang="scss">
.block-m-input {
  width: 100%;

  .m-input {
    padding: 16px 14px;
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;

    &::placeholder {
      color: rgba(194, 194, 194, 1);
      font-size: 14px;
    }

    &:hover {
      border: 1px solid #e2e8f0;
      box-shadow: 0 0 0 2px #e2e8f0;
    }
  }
  .error {
    color: red;
  }
}
</style>
