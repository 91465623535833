<template>
  <button class="m-btn" :style="{ width: widthButton, height: heightButton }">
    <img :src="imgUrl" alt="img" v-if="imgUrl" />
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "m-button",
  props: {
    imgUrl: {
      type: String,
    },
    widthButton: {
      type: String,
      deffault: "100px",
    },
    heightButton: {
      type: String,
      deffault: "56px",
    },
  },
};
</script>
<style lang="scss">
.m-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  border: none;
  // height: 56px;
  background: #e2f54b;
  border-radius: 32px;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;

  &:hover {
    background: #b6c92a;
  }
}
</style>
