<template>
  <div class="container">
    <div class="header">
      <div class="title">Deposit</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Deposit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">ID</span>
        </div>
        <m-input placeholder="ID" v-model="form.title" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">User</span>
        </div>
        <div class="width100">johndoe@gmail.com</div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Currency</span>
        </div>
        <m-input placeholder="Currency" v-model="form.title" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Sent amount</span>
        </div>
        <m-input placeholder="Sent amount" v-model="form.title" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Wallet address</span>
        </div>
        <div class="width100">memo:q2C463EhJtOfuG0ZiY7g</div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee</span>
        </div>
        <m-input placeholder="Fee" v-model="form.slug" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Status</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select v-model:value="form.pair" :options="options" />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Data created</span>
        </div>
        <m-input placeholder="Data created" v-model="form.slug" />
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DepositEditPage",
  props: {
    id: {
      type: String,
    },
  },
  data: () => {
    return {
      form: {
        title: "",
        slug: "",
        permissions: [],
      },
      options: [
        {
          label: "Confirmed",
          value: "Confirmed",
        },
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Canceled",
          value: "Canceled",
        },
      ],
    };
  },
  methods: {
    createRole() {
      this.$router.push("/roles/create");
    },
    removeSelectedFilter(index) {
      delete this.form.permissions[index];
    },
    isChecked(index) {
      return !!this.form.permissions[index];
    },
    allSelect() {
      this.form.permissions = this.form.permissions.length
        ? []
        : this.listRoles[0].permissions;
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
<style lang="scss">
.textTop {
  vertical-align: top;
}
.block-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-title {
  width: 200px;
}
.block-checkbox {
  display: flex;
  margin-bottom: 20px;
  .permissions-title {
    padding-right: 95px;
  }
}
.ul-style {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}
.content-block__button {
  display: flex;
  justify-content: end;
  gap: 10px;
  .btn_cancel {
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 32px;
    &:hover {
      background: #eeeeee;
    }
  }
}
</style>
