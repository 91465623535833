<template>
  <div>
    <button
      class="btn-dropbox"
      @click="isCloseDrop = !isCloseDrop"
      :id="getRefSring(name)"
    >
      <div class="img-svg-left">
        <img :src="require(`@/assets/icons/${icon}`)" :alt="icon" />
      </div>
      <span class="show btn-link" :class="{ hide: !isOpened }">{{ name }}</span>
      <div class="img-svg-right">
        <img
          :src="require(`@/assets/icons/drobbox_array_up.svg`)"
          :alt="icon"
          class="icon-stat"
          :class="{ rotate: isCloseDrop, hide: !isOpened }"
        />
      </div>
    </button>
    <div class="drop" :class="{ close: isCloseDrop }">
      <div v-for="btn in menuDropbox" :key="btn.title">
        <div v-if="showButton(btn)">
          <button class="drop-btn-block" v-if="btn.type === 'button'">
            <router-link class="drop-btn-link" :to="btn.link"
              ><span class="show" :class="{ hide: !isOpened }">{{
                btn.title
              }}</span></router-link
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "CollapseComponent",
  data: () => {
    return {
      isCloseDrop: true,
    };
  },
  props: {
    name: {
      type: String,
      default: "name",
    },
    menuDropbox: {
      type: Array,
    },
    isOpened: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: "UsersStore/getListOwnerPermissions",
    }),
  },
  mounted() {
    ref(document.getElementById(this.name));
  },
  methods: {
    getRefSring(str) {
      return str.toLowerCase();
    },
    showButton(elem) {
      if (!elem.permission) return true;
      if (elem.type === "dropbox") {
        elem.type;
        return true;
      }
      return this.listOwnerPermissions.find((element) => {
        return element === elem.permission;
      })
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/style.scss";

.btn-dropbox {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  color: #ffffff;
  border-right: 3px solid $bg-color-menu;

  .img-svg-left {
    display: flex;
    height: 25px;
    width: 25px;
    margin-right: 15px;
    filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(29deg)
      brightness(107%) contrast(108%);
  }
  .img-svg-right {
    height: 15px;
    width: 15px;
    margin-right: 10px;

    filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(29deg)
      brightness(107%) contrast(108%);
  }
  &:hover {
    border-right: 3px solid $color-active-btn;
    .btn-link {
      color: $color-active-btn;
    }
    .img-svg-right {
      filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg)
        brightness(100%) contrast(93%);
    }
    .img-svg-left {
      filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg)
        brightness(100%) contrast(93%);
    }
  }
}
.icon-stat {
  transition: all 0.3s ease;
}
.drop {
  height: auto;
  overflow: hidden;
  &-btn-block {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    cursor: pointer;
    color: #ffffff;

    .drop-btn-link {
      padding-left: 30px;
      display: flex;
      width: 100%;
      color: #ffffff;
      text-decoration: none;
      font-size: 16px;
    }
    &:hover {
      border-right: 3px solid $color-active-btn;
      .drop-btn-link {
        color: $color-active-btn;
      }
    }
  }
}
.active {
  border-right: 3px solid $color-active-btn;
  .btn-link {
    color: $color-active-btn;
  }
  .img-svg-right {
    filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg)
      brightness(100%) contrast(93%);
  }
  .img-svg-left {
    filter: invert(99%) sepia(97%) saturate(1147%) hue-rotate(7deg)
      brightness(100%) contrast(93%);
  }
}
.close {
  height: 0;
}
.rotate {
  transition: all 0.3s ease;
  transform: rotate(-180deg);
}
</style>
