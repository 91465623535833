<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Users</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/users">Users</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Username</span>
        </div>
        <m-input placeholder="Username" v-model="form.name" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Email</span>
        </div>
        <m-input placeholder="Email" v-model="form.email" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Phone</span>
        </div>
        <m-input placeholder="Phone" v-model="form.phone" />
      </div>
      <div class="block-checkbox">
        <div class="col-title">
          <span class="subtitle">Role</span>
        </div>
        <n-checkbox-group v-model:value="form.roles" size="large">
          <n-space item-style="display: flex;" vertical>
            <n-checkbox :value="item.value" :label="item.label" v-for="(item, index) in listRoles" :key="index" />
          </n-space>
        </n-checkbox-group>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="save">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UsersCreatePage',
  data: () => {
    return {
      listRoles: [],
      form: {
        name: '',
        email: '',
        phone: '',
        roles: []
      }
    }
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    async save() {
      const url = '/users/user'
      const { name, email, phone, roles } = this.form
      const data = {
        name,
        email,
        phone,
        roles
      }
      this.$api
        .post(url, data)
        .then(async () => {
          await this.alertShow('success', 'User create')
        })
        .catch(() => {
          this.alertShow('error', 'User not create')
        })
    },
    fetchRoles() {
      const url = '/roles'
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listRoles = data.map((el) => ({ value: el, label: el.name }))
        })
    }
  }
}
</script>
<style lang="scss"></style>
