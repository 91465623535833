export const UsersStore = {
  namespaced: true,
  state: () => ({
    isAuthenticated: corectAuthenticated(),
    listUsers: [],
    totalUsers: '',
    pageUserPagination: '',
    listOwnerRoles: [],
    listOwnerPermissions: JSON.parse(window.localStorage.getItem('permissions')) || []
  }),
  getters: {
    getIsAuthenticated: (s) => s.isAuthenticated,
    getListUsers: (s) => s.listUsers,
    getTotalUsers: (s) => s.totalUsers,
    getPageUserPagination: (s) => s.pageUserPagination,
    getListOwnerRoles: (s) => s.listOwnerRoles,
    getListOwnerPermissions: (s) => s.listOwnerPermissions
  },
  mutations: {
    isAuthenticated: (state, isAuthenticated) => {
      state.isAuthenticated = isAuthenticated
    },
    setListUsers: (state, data) => {
      state.listUsers = data
    },
    setTotalUsers: (state, data) => {
      state.totalUsers = data
    },
    setPageUserPagination: (state, data) => {
      state.pageUserPagination = data
    },
    setListOwnerRoles: (state, data) => {
      state.listOwnerRoles = data
    },
    setListOwnerPermissions: (state, data) => {
      state.listOwnerPermissions = data
    }
  },
  actions: {
    async fetchListUsers({ commit }, data) {
      const url = '/users'
      const { limit, page, search, roleId, dateFrom, dateTo } = data
      this.$api
        .get(url, {
          params: {
            limit,
            page,
            search,
            roleId,
            dateFrom,
            dateTo
          }
        })
        .then((res) => res.data)
        .then((data) => {
          commit('setListUsers', data.users)
          commit('setTotalUsers', data.total)
          commit('setPageUserPagination', data.page)
        })
    },
    async fetchUserById({ commit }, id) {
      const url = `/users/user/${id}`
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          console.log(data)
          commit('setUser', data.users)
        })
    },
    async fetchUserRoles({ commit }) {
      const url = `/users/owner-roles`
      this.$api
        .get(url)
        .then((res) => res.data)
        .then(async (data) => {
          const roles = []
          const permissions = []
          let listPermissions = []

          if (data.length) {
            await data.map((el) => {
              const role = { ...el, permissions: JSON.parse(el.permissions) }
              roles.push(role)
              listPermissions.push(JSON.parse(el.permissions))
            })
          }
          listPermissions = listPermissions.flat()
          for (let str of listPermissions) {
            if (!permissions.includes(str)) {
              permissions.push(str)
            }
          }
          commit('setListOwnerRoles', roles)
          commit('setListOwnerPermissions', permissions)
          window.localStorage.setItem('permissions', JSON.stringify(permissions))
          window.localStorage.setItem('roles', JSON.stringify(roles))
        })
    }
  }
}
const corectAuthenticated = () => {
  const locStExpires = localStorage.getItem('expires_at') * 1000
  const dateNow = new Date().getTime()
  if (locStExpires) {
    return dateNow < locStExpires
  } else {
    return false
  }
}
