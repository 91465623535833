<template>
  <div class="container">
    <div class="header">
      <div class="title">Dashbord</div>
    </div>
    <div class="block-content">
      <div class="block-cards">
        <CardComponent
          countTitle="Total users"
          countNumber="361"
          :imgUrl="require('@/assets/icons/users-card.svg')"
          filterImg="invert(33%) sepia(86%) saturate(316%) hue-rotate(175deg) brightness(90%) contrast(91%)"
          colorBord="#4E719F"
          colorButton="#3F6290"
        />
        <CardComponent
          countTitle="Total deals"
          countNumber="1000"
          :imgUrl="require('@/assets/icons/deal-card.svg')"
          filterImg="invert(68%) sepia(12%) saturate(959%) hue-rotate(189deg) brightness(86%) contrast(92%)"
          colorBord="#9BA9D8"
          colorButton="#8795C4"
        />
        <CardComponent
          countTitle="Support request"
          countNumber="647"
          :imgUrl="require('@/assets/icons/support-card.svg')"
          filterImg="invert(69%) sepia(7%) saturate(2877%) hue-rotate(9deg) brightness(93%) contrast(99%)"
          colorBord="#D3B75A"
          colorButton="#C2A649"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardComponent from "@/components/dashbord/card";
export default {
  name: "DashBord",
  components: { CardComponent },
};
</script>
<style lang="scss">
.block-cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 20px;
}
</style>
