<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Payment Methods</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/payment-method"
          >Payment Methods</router-link
        >/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Id</span>
        </div>
        <div class="width100">{{ id }}</div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Payment method</span>
        </div>
        <m-input
          placeholder="Payment method name"
          :value="form.name"
          v-model="form.name"
        />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Payment method parents</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select
              v-model:value="form.parentId"
              :value="form.parentId"
              :options="listPaymentMethod"
              placeholder="Payment method parents"
            />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Description buy</span>
        </div>
        <div class="width100">
          <textarea
            class="textareaDesc"
            v-model="form.descriptionBuy"
            placeholder="Description buy"
          ></textarea>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Description sell</span>
        </div>
        <div class="width100">
          <textarea
            class="textareaDesc"
            v-model="form.descriptionSell"
            placeholder="Description sell"
          ></textarea>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Transaction time min</span>
        </div>
        <div class="style-select">
          <div class="time-input">
            <label for="time-day">Days</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.days"
                button-placement="both"
                min="0"
                max="10"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Hours</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.hours"
                button-placement="both"
                min="0"
                max="23"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Minutes</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="minTime.minutes"
                button-placement="both"
                min="0"
                max="59"
                size=""
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Transaction time max</span>
        </div>
        <div class="style-select">
          <div class="time-input">
            <label for="time-day">Days</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.days"
                button-placement="both"
                min="0"
                max="10"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Hours</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.hours"
                button-placement="both"
                min="0"
                max="23"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
          <div class="time-input">
            <label for="time-day">Minutes</label>
            <n-space style="gap: 0">
              <n-input-number
                id="time-day"
                v-model:value="maxTime.minutes"
                button-placement="both"
                min="0"
                max="59"
                :disabled="disabledInputNumber"
              />
            </n-space>
          </div>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Minimum transaction cost limit</span>
        </div>
        <m-input
          placeholder="Minimum transaction cost limit"
          v-model="form.transactionLimitCost"
        />
      </div>
      <div v-if="form.parentId !== null" class="row">
        <div class="block-input">
          <div class="col-title">
            <span class="subtitle">Fee BTC</span>
          </div>
          <m-input
              type="number"
              placeholder="Fee BTC"
              v-model="feeBtc"
          />
        </div>
        <div class="block-input">
          <div class="col-title">
            <span class="subtitle">Fee USDT</span>
          </div>
          <m-input
              type="number"
              placeholder="Fee USDT"
              v-model="feeUsdt"
          />
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isActive" />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle"> Is showing as tag </span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isShowingAsTag" />
          </n-space>
        </div>
      </div>
      <div v-if="parentId === null" class="block-input">
        <div class="col-title">
          <span class="subtitle">Icon</span>
        </div>
        <div class="width100">
          <img
            v-if="form.whiteThemeImageLink && form.whiteThemeImageLink !== 'null'"
            :src="form.whiteThemeImageLink"
            alt=""
            width="200"
            height="200"
          />
          <m-button
            v-if="form.whiteThemeImageLink && form.whiteThemeImageLink !== 'null'"
            style="margin-top: 20px"
            @click="deleteImageWhite"
            >Delete image</m-button
          >
          <m-upload v-else name="whiteThemeImage" v-model="imageWeb" style="margin-top: 20px" />
        </div>
        <div class="width100">
          <img
              v-if="form.blackThemeImageLink && form.blackThemeImageLink !== 'null'"
              :src="form.blackThemeImageLink"
              alt=""
              width="200"
              height="200"
          />
          <m-button
              v-if="form.blackThemeImageLink && form.blackThemeImageLink !== 'null'"
              style="margin-top: 20px"
              @click="deleteImageBlack"
          >Delete image</m-button
          >
          <m-upload v-else name="blackThemeImage" v-model="imageWeb2" style="margin-top: 20px" />
        </div>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updatePaymentMethod">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LimitsEditPage",
  data: () => {
    return {
      imageWeb: null,
      imageWeb2: null,
      minTime: { days: 0, hours: 0, minutes: 0 },
      maxTime: { days: 0, hours: 0, minutes: 0 },
      listParents: [],
      parentId: null,
      disabledInputNumber: false,
      feeBtc: 0,
      feeUsdt: 0,
      form: {
        id: 7,
        imageId: "",
        name: "",
        parentId: null,
        transactionLimitCost: 10,
        transactionLimitTimeMin: 30,
        transactionLimitTimeMax: 30,
        blackThemeImageLink: "",
        whiteThemeImageLink: "",
        descriptionBuy: "",
        descriptionSell: "",
        isActive: false,
        isShowingAsTag: false,
      },
      options: [
        {
          label: "Minutes",
          value: "Minutes",
        },
        {
          label: "Hours",
          value: "Hours",
        },
      ],
      listPaymentMethod: [
        {
          label: "Bank transfer",
          value: "1",
        },
        {
          label: "Online wallets",
          value: "2",
        },
        {
          label: "Credit cards",
          value: "3",
        },
        {
          label: "Gift card",
          value: "4",
        },
        {
          label: "Digital currencies",
          value: "5",
        },
        {
          label: "Cash payment",
          value: "6",
        },
        {
          label: "Goods & Services",
          value: "7",
        },
      ],
    };
  },
  props: {
    id: { type: String },
  },
  watch: {
    "form.parentId"(event) {
      if (this.parentId) {
        const parentMethod = this.listParents.find((el) => el.id === event);
        this.getDateFromTimestamp(parentMethod.transactionLimitTimeMax, "max");
        this.getDateFromTimestamp(parentMethod.transactionLimitTimeMin, "min");
        this.form.whiteThemeImageLink = parentMethod.whiteThemeImageLink;
      }
      this.parentId = event;

      this.disabledInputNumber = true;
    },
  },
  computed: {},
  mounted() {
    this.fetchPaymentMethod();
    this.fetchPaymentMethodParents();
  },
  methods: {
    deleteImageWhite() {
      this.form.whiteThemeImageLink = "";
    },
    deleteImageBlack() {
      this.form.blackThemeImageLink = "";
    },
    fetchPaymentMethodParents() {
      const url = `/payment-methods/all-parents`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listParents = data;
          this.listPaymentMethod = data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });
        });
    },
    fetchPaymentMethod() {
      const url = `/payment-methods/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.getDateFromTimestamp(data.transactionLimitTimeMax, "max");
          this.getDateFromTimestamp(data.transactionLimitTimeMin, "min");
          this.form = data;
          this.feeUsdt = this.form.paymentMethodFees.filter(el => el.coin === 'USDT')[0] ? this.form.paymentMethodFees.filter(el => el.coin === 'USDT')[0].fee : 0
          this.feeBtc = this.form.paymentMethodFees.filter(el => el.coin === 'BTC')[0] ? this.form.paymentMethodFees.filter(el => el.coin === 'BTC')[0].fee : 0
        });
    },
    getDateFromTimestamp(timestamp, fold) {
      let seconds = Math.floor(timestamp / 1000);
      let minutes = Math.floor((seconds % 3600) / 60);
      let hours = Math.floor((seconds % (3600 * 24)) / 3600);
      let days = Math.floor(seconds / (3600 * 24));
      if (fold === "max") {
        this.maxTime.days = days;
        this.maxTime.hours = hours;
        this.maxTime.minutes = minutes;
      }

      if (fold === "min") {
        this.minTime.days = days;
        this.minTime.hours = hours;
        this.minTime.minutes = minutes;
      }
    },
    getTimestampWithDate(timeObj) {
      const { days, hours, minutes } = timeObj;
      return (days * 24 * 3600 + hours * 3600 + minutes * 60) * 1000;
    },
    updatePaymentMethod() {
      this.form.transactionLimitTimeMax = this.getTimestampWithDate(
        this.maxTime
      );
      this.form.transactionLimitTimeMin = this.getTimestampWithDate(
        this.minTime
      );

      const parentMethod = this.listParents.find(
        (el) => el.id === this.parentId
      );
      if (parentMethod?.whiteThemeImageLink) {
        this.form.whiteThemeImageLink = parentMethod.whiteThemeImageLink;
      }

      const url = `/payment-methods/${this.id}`;
      const formData = new FormData();
      if (this.imageWeb) {
        formData.append("images", this.imageWeb, 'whiteThemeImage');
      }
      if (this.imageWeb2) {
        formData.append("images", this.imageWeb2, 'blackThemeImage');
      }
      for (let el in this.form) {
        formData.append(el, this.form[el]);
      }
      formData.delete('paymentMethodFees')
      formData.append('paymentMethodFees', JSON.stringify([
        {
          coin: 'BTC',
          fee: this.feeBtc
        },
        {
          coin: 'USDT',
          fee: this.feeUsdt
        }
      ]))

      this.$api
        .put(url, formData)
        .then(() => {
          this.alertShow("success", "Payment method update");
        })
        .catch(() => {
          this.alertShow("error", "Payment method not update");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  gap: 14px;
}
</style>
