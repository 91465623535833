const colorGrey = "#3E4659";

export const themeOverrides = {
  common: {
    // primaryColor: "blue",
  },
  Button: {
    borderRadiusMedium: "32px",
  },
  Upload: {
    borderRadius: "32px",
    peers: {
      Button: {
        colorOpacitySecondary: "red",
        colorOpacitySecondaryHover: "red",
        colorOpacitySecondaryPressed: "red",
        colorSecondary: "red",
        colorSecondaryHover: "red",
        colorSecondaryPressed: "red",
        colorTertiary: "red",
        colorTertiaryHover: "red",
        colorTertiaryPressed: "red",
        colorQuaternary: "red",
        colorQuaternaryHover: "red",
        colorQuaternaryPressed: "red",
        color: "red",
        colorHover: "red",
        colorPressed: "red",
        colorFocus: "red",
        colorDisabled: "red",
        borderRadiusMedium: "32px",
        textColor: "#ffffff",
      },
    },
  },
  Select: {
    peers: {
      InternalSelection: {
        textColor: "#000000",
        borderRadius: "12px",
        border: "1px solid #E2E8F0",
        borderHover: "1px solid #E2E8F0",
        borderActive: "1px solid #E2E8F0",
        borderFocus: "1px solid #E2E8F0",
        boxShadowHover: "0 0 0 2px #E2E8F0",
        boxShadowActive: "0 0 0 2px #E2E8F0",
        boxShadowFocus: "0 0 0 2px #E2E8F0",
        clearSize: "30px",
        arrowSize: "30px",
        heightMedium: "56px",
        arrowColor: "#6B7386",
        paddingSingle: "20px",
      },
      InternalSelectMenu: {
        optionTextColor: "#000000",
      },
    },
  },
  Checkbox: {
    sizeLarge: "24px",
    borderRadius: "4px",
    border: `2px solid ${colorGrey}`,
    borderChecked: `2px solid ${colorGrey}`,
    borderFocus: `1px solid ${colorGrey}`,
    colorChecked: colorGrey,
  },
  Tabs: {
    tabTextColorActiveLine: "#000000",
    tabTextColorHoverLine: "#000000",
    tabTextColorLine: "blue",
    barColor: "blue",
  },
  InputNumber: {
    peers: {
      Button: {
        iconMarginSmall: "50px",
        iconMarginTiny: "50px",
        iconMarginMedium: "50px",
        iconMarginLarge: "50px",
        iconSizeTiny: "50px",
        iconSizeSmall: "50px",
        iconSizeMedium: "50px",
        iconSizeLarge: "50px",
        rippleDuration: "50px",
        paddingTiny: "50px",
        paddingSmall: "50px",
        paddingMedium: "50px",
        paddingLarge: "50px",
        paddingRoundTiny: "50px",
        paddingRoundSmall: "50px",
        paddingRoundMedium: "50px",
        paddingRoundLarge: "50px",
      },
      Input: {
        heightLarge: "56px",
        fontSizeLarge: "30px",
        countTextColor: "#3E4659",
        border: "1px solid #E2E8F0",
        borderHover: "1px solid #E2E8F0",
        borderDisabled: "1px solid #E2E8F0",
        borderFocus: "1px solid #E2E8F0",
        boxShadowFocus: "0 0 0 2 #E2E8F0",
        borderRadius: "12px",
        paddingLarge: "0 50px",
      },
    },
  },
};
