export const SocketEventStore = {
  namespaced: true,
  state: () => ({
    event: { messa: "dk" },
  }),
  getters: {
    getEvent(state) {
      return state.event;
    },
  },
  mutations: {
    setEvent(state, event) {
      state.event = event;
    },
  },
  actions: {},
};
