<template>
  <div class="container">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
    <div class="header">
      <div class="title">Categories</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/faq/categories">Category</router-link>/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Name</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <m-input v-model="title_en"></m-input>
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <m-input></m-input>
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <m-input></m-input>
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle"> Is showing as tag </span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="isPublish" />
          </n-space>
        </div>
      </div>
      <div class="content-block__button">
        <m-button @click="$router.push('/faq/categories')" widthButton="101px" class="btn_cancel">
          <span>Cancel</span>
        </m-button>
        <m-button @click="createCategory" widthButton="101px">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FAQCategoriesEditPage",
  props: {
    id: String,
  },
  data: () => ({
    title_en: '',
    isPublish: false
  }),
  mounted() {
    this.$api
        .get('/faq-category/' + this.id)
        .then(({data}) => {
          this.title_en = data.title;
          this.isPublish = data.isPublish;
        });
  },
  methods: {
    createCategory() {
      this.$api
          .put('/faq-category/', {
            id: +this.id,
            title: this.title_en,
            isPublish: this.isPublish,
          })
          .then(() => {
            this.alertShow("success", "Category created");
            this.$router.push('/faq/categories')
          })
          .catch(() => {
            this.alertShow("error", "Category not edit");
          });
    }
  }
};
</script>
<style lang=""></style>
