import { createStore } from "vuex";
import { AuthStore } from "./auth";
import { UsersStore } from "./users";
import { SocketEventStore } from "./socket";

export default createStore({
  modules: {
    AuthStore,
    UsersStore,
    SocketEventStore,
  },
});
