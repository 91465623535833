import axios from 'axios'
import store from '@/store/index'
console.log('Processs', process.env)
const loginInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json'
  }
})
console.log('Beear', store.getters['AuthStore/getToken'])
const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${store.getters['AuthStore/getToken']}`
  }
})

export default { loginInstance, apiInstance }
