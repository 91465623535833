export const dealsTypeEnum = {
  userMessage: "user_message",
  uploadFile: "upload_file",
  adminUploadFile: "admin_upload_file",
  systemMessage: "system_message",
  adminMessage: "admin_message",
  dealAccepted: "deal_accepted",
  disputeOpen: "dispute_open",
  dealCanceled: "deal_canceled",
  dealCompleted: "deal_completed",
  moneySent: "money_sent",
  dealOpen: "deal_open",
  moderatorConnect: "moderator_connect",
};
