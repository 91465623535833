<template>
  <div class="container">
    <div class="header">
      <div class="title">Withdrawals</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Withdrawals</router-link>
      </div>
    </div>
    <div class="block-content">
      <SearchWithdrawals
        v-model:status="status"
        v-model:withdrawalId="withdrawalId"
        @search="search"
      />
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColums(paramsFilter.page, paramsFilter.limit)"
          :data="list"
          :single-line="false"
        />
        <n-pagination
          class="top20"
          :item-count="total"
          size="large"
          :default-page-size="paramsFilter.limit"
          @update:page="updatePage"
        >
        </n-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { h } from "vue";
import { NTag } from "naive-ui";
import SearchWithdrawals from "@/components/withdrawals/searchWithdrawals.vue";
export default {
  name: "WithdrawalPage",
  data: () => {
    return {
      paramsFilter: {
        page: 1,
        limit: 10,
        status: null,
        id: null,
      },
      replenishmentType: null,
      status: null,
      withdrawalId: null,
      total: null,
      createColums: (page, limit) => {
        return [
          {
            title: "#",
            key: "id",
            render: (_, index) => {
              return `${index + 1 + (page - 1) * limit}`;
            },
          },
          {
            title: "ID",
            key: "id",
          },
          {
            title: "User",
            key: "fromUser.name",
          },
          {
            title: "Coin",
            key: "paidCoin",
          },
          {
            title: "Amount",
            key: "paidAmount",
          },
          {
            title: "Network",
            key: "network",
          },
          {
            title: "Wallet address",
            key: "toAddress",
          },
          {
            title: "Status",
            key: "status",
            render(row) {
              const status = row.status;
              let colorBG = "";
              let colorText = "";
              if (status === "success") {
                colorBG = "#EDF6F2";
                colorText = "#4DA97D";
              } else if (status === "reject") {
                colorBG = "#FEEFF2";
                colorText = "#C04949";
              } else {
                colorBG = "#F1F5F9";
                colorText = "#64748B";
              }
              return h(
                NTag,
                {
                  style: {
                    padding: "16px",
                    color: colorText,
                    background: colorBG,
                    fontSize: "14px",
                  },
                  round: true,
                  bordered: false,
                },
                {
                  default: () => {
                    return `${row.status}`;
                  },
                }
              );
            },
          },
          // {
          //   title: "Replenishment",
          //   key: "replenishmentType",
          //   render(row) {
          //     const status = row.replenishmentType;
          //     let colorBG = "";
          //     let colorText = "";
          //     if (status === "receive") {
          //       colorBG = "#EDF6F2";
          //       colorText = "#4DA97D";
          //     } else if (status === "send") {
          //       colorBG = "#FEEFF2";
          //       colorText = "#C04949";
          //     }
          //     return h(
          //       NTag,
          //       {
          //         style: {
          //           padding: "16px",
          //           color: colorText,
          //           background: colorBG,
          //           fontSize: "14px",
          //         },
          //         round: true,
          //         bordered: false,
          //       },
          //       {
          //         default: () => {
          //           return `${row.replenishmentType}`;
          //         },
          //       }
          //     );
          //   },
          // },
          {
            title: "Data created",
            key: "createdAt",
            render(row) {
              const date = new Date(row.createdAt);
              const year = date.getFullYear();
              const day = date.getDate();
              const correctDay = day < 10 ? `0${day}` : day;
              const mount = date.getMonth();
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const seconds = date.getSeconds();
              const correctMinutes = minutes < 10 ? `0${minutes}` : minutes;
              const correctSeconds = seconds < 10 ? `0${seconds}` : seconds;
              const correctMount = mount < 10 ? `0${mount}` : mount;
              return `${correctDay}.${correctMount}.${year} ${hours}:${correctMinutes}:${correctSeconds}`;
            },
          },
        ];
      },
      list: [],
    };
  },
  components: {
    SearchWithdrawals,
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      const url = "/transaction?type=withdrawals";
      this.paramsFilter.id = this.withdrawalId ? this.withdrawalId : null;
      this.paramsFilter.status = this.status;

      this.$api
        .get(url, {
          params: this.paramsFilter,
        })
        .then((res) => {
          console.log(res);
          this.list = res.data.transactions;
          this.total = res.data.total;
        })
    },
    updatePage(page) {
      this.paramsFilter.page = page;
      this.fetchList();
    },
    search() {
      this.fetchList();
    },
  },
};
</script>
<style lang=""></style>
