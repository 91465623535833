<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <Saidakbarov />
    <router-view></router-view>
    <WebSocket />
  </n-config-provider>
</template>

<script>
import Saidakbarov from "./components/sidebar/sidebar.vue";
// import { NConfigProvider } from "naive-ui";
import { themeOverrides } from "./customizeTheme";
import("naive-ui").GlobalThemeOverrides;
import WebSocket from "./components/WebSocket.vue";

export default {
  name: "App",
  components: {
    Saidakbarov,
    WebSocket,
  },
  data() {
    return {
      is_expanded: localStorage.getItem("is_expanded"),
      themeOverrides: themeOverrides,
    };
  },
  computed() {},
};
</script>

<style></style>
