<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Fee</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/fee">Fee</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>

    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Id</span>
        </div>
        <div class="width100">{{ id }}</div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Coin</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select v-model:value="createParams.coin" :options="listCoin" />
            <div class="error" v-if="v$.createParams.coin.$error">
              Invalid field. Choose coin!
            </div>
          </n-space>
        </div>
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Wallet</span>
        </div>
        <div class="width100">
          <n-space vertical>
            <n-select
              v-model:value="createParams.wallet"
              :options="listWallet"
            />
            <div class="error" v-if="v$.createParams.wallet.$error">
              Invalid field. Choose wallet!
            </div>
          </n-space>
        </div>
      </div>

      <!-- feeOfLess10$ -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee of 0$-9.99$</span>
        </div>
        <m-input
          placeholder="Fee of 0$-9.99$"
          type="number"
          :step="0.001"
          :min="0"
          v-model:modelValue="createParams.feeOfLess10$"
        />
      </div>
      <!-- feeOfFrom10To20$ -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee of 10$ - 19.99$</span>
        </div>
        <m-input
          placeholder="Fee of 10$ - 19.99$"
          type="number"
          :step="0.001"
          :min="0"
          v-model:modelValue="createParams.feeOfFrom10To20$"
        />
      </div>

      <!-- feeOfMore20$ -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Fee of 20$+</span>
        </div>
        <m-input
          placeholder="Fee of 20$+"
          type="number"
          :step="0.001"
          :min="0"
          v-model:modelValue="createParams.feeOfMore20$"
        />
      </div>

      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="createParams.isActive" />
          </n-space>
        </div>
      </div>

      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updateFeeWithdrawals">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "FeeWithdrawalsCreatePage",
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      listCoin: [
        {
          label: "BTC",
          value: "BTC",
        },
        {
          label: "USDT",
          value: "USDT",
        },
      ],
      listWallet: [
        {
          label: "internal",
          value: "internal",
        },
        {
          label: "external",
          value: "external",
        },
      ],
      createParams: {
        coin: null,
        wallet: null,
        feeOfLess10$: 0.0,
        feeOfFrom10To20$: 0.0,
        feeOfMore20$: 0.0,
        isActive: false,
      },
    };
  },
  validations() {
    return {
      createParams: {
        wallet: { required },
        coin: { required },
      },
    };
  },
  mounted() {
    this.fetchFeeWithdrawals();
  },
  props: {
    id: Number,
  },
  methods: {
    async updateFeeWithdrawals() {
      await this.v$.createParams.$touch();
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        console.log(this.createParams);
        const url = `/fees/withdrawals`;
        this.$api
          .post(url, this.createParams)
          .then(() => {
            this.alertShow("success", "Fee withdrawals update");
          })
          .catch(() => {
            this.alertShow("error", "Fee withdrawals not update");
          });
      }
    },
    async fetchFeeWithdrawals() {
      const url = `/fees/withdrawals/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.createParams = { ...data };
        });
    },
  },
};
</script>
<style lang="scss">
.error {
  color: red;
}
</style>
