<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container" :class="{ 'active-modal': showModal }">
    <div class="header">
      <div class="title">Users</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Users</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-button">
        <m-button :imgUrl="require('@/assets/icons/plus-button.svg')" widthButton="148px" @click="create">
          <span>Create</span>
        </m-button>
      </div>
      <m-form-search
        v-model:search="form.search"
        v-model:roleId="form.roleId"
        v-model:dateFrom="form.dateFrom"
        v-model:dateTo="form.dateTo"
        @search="search"
        :listRoles="listRoles"
        selectShow="true"
      >
      </m-form-search>
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute)"
          :data="listUsers"
          :single-line="false"
        />
        <n-pagination class="top20" :item-count="totalUsers" size="large" @update:page="updatePage">
          <template #prefix="{ itemCount, startIndex }">
            Showing {{ startIndex }} to {{ endIndex }} of {{ itemCount }} entries
          </template>
        </n-pagination>
      </div>
    </div>
    <m-modal
      :open="showModalDelete"
      :title="modelTitle"
      :subtitle="modelSubtitle"
      :nameButtonActive="nameButtonActive"
      @cancel-event="closeModel"
      @active-event="deleteUser(userId)"
    ></m-modal>
    <m-modal
      :open="showModalBlock"
      :title="modelTitle"
      :subtitle="modelSubtitle"
      :nameButtonActive="nameButtonActive"
      @cancel-event="closeModel"
      @active-event="blockedUser(userId)"
    ></m-modal>
  </div>
</template>
<script>
import { h } from 'vue'
import { NTag, NButton } from 'naive-ui'
import { mapGetters } from 'vuex'
export default {
  name: 'UsersPage',
  data: () => {
    return {
      createColumns: (openModal, goToRoute) => {
        return [
          {
            title: 'Account id',
            key: 'id'
          },
          {
            title: 'Role',
            key: 'roles',
            render(row) {
              if (row.roles.length) {
                return row.roles.map((el) => el.name).join(', ')
              } else {
                return ``
              }
            }
          },
          {
            title: 'User info',
            key: 'email'
          },
          {
            title: 'Data created',
            key: 'createdAt',
            render(row) {
              const year = new Date(row.createdAt).getFullYear()
              const day = new Date(row.createdAt).getDate()
              const correctDay = day < 10 ? `0${day}` : day
              const mount = new Date(row.createdAt).getMonth()
              const correctMount = mount < 10 ? `0${mount}` : mount
              return `${correctDay}.${correctMount}.${year}`
            }
          },
          {
            title: 'E-mail',
            key: 'isEmailConfirmed',
            render(row) {
              const validConfirmed = row.isEmailConfirmed
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: validConfirmed ? '#4DA97D' : '#C04949',
                    background: validConfirmed ? '#EDF6F2' : '#FEEFF2',
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    if (validConfirmed) {
                      return 'Verified'
                    } else {
                      return 'Not verified'
                    }
                  }
                }
              )
            }
          },
          {
            title: 'Phone',
            key: 'isPhoneNumberConfirmed',
            render(row) {
              const validConfirmed = row.isPhoneNumberConfirmed
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: validConfirmed ? '#4DA97D' : '#C04949',
                    background: validConfirmed ? '#EDF6F2' : '#FEEFF2',
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    if (validConfirmed) {
                      return 'Verified'
                    } else {
                      return 'Not verified'
                    }
                  }
                }
              )
            }
          },
          {
            title: 'KYC',
            key: 'kyc',
            render(row) {
              const validConfirmed = row.kyc
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: validConfirmed ? '#4DA97D' : '#C04949',
                    background: validConfirmed ? '#EDF6F2' : '#FEEFF2',
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    if (validConfirmed) {
                      return 'Verified'
                    } else {
                      return 'Not verified'
                    }
                  }
                }
              )
            }
          },
          {
            title: 'Action',
            key: 'actions',
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: '#E2F54B',
                      padding: '4px',
                      width: '32px',
                      height: '32px',
                      'border-radius': '8px 0px 0px 8px'
                    },
                    size: 'large',
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute('user-edit', row.id)
                  },
                  {
                    default: () => {
                      return h('img', {
                        src: require('@/assets/icons/button-edit.svg'),
                        alt: 'edit'
                      })
                    }
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: '#FFFFFF',
                      padding: '4px',
                      width: '32px',
                      height: '32px',
                      border: '1px solid #CBD5E1'
                    },
                    size: 'large',
                    vertical: false,
                    quaternary: true,
                    onClick: () => openModal('block', row.name)
                  },
                  {
                    default: () => {
                      return h('img', {
                        src: require('@/assets/icons/button-block.svg'),
                        alt: 'block'
                      })
                    }
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: '#0F172A',
                      padding: '4px',
                      width: '32px',
                      height: '32px',
                      'border-radius': '0px 8px 8px 0px',
                      border: 'none'
                    },
                    quaternary: true,
                    size: 'large',
                    vertical: false,
                    onClick: () => openModal('delete', row.name)
                  },
                  {
                    default: () => {
                      return h('img', {
                        src: require('@/assets/icons/button-delete.svg'),
                        alt: 'delete'
                      })
                    }
                  }
                )
              ]
            }
          }
        ]
      },
      listRoles: [],
      form: {
        search: '',
        roleId: '',
        dateFrom: '',
        dateTo: ''
      },
      showModal: false
    }
  },
  mounted() {
    this.$store.dispatch('UsersStore/fetchListUsers', {
      limit: 10,
      page: 1,
      search: '',
      roleId: '',
      dateFrom: '',
      dateTo: ''
    })
    this.fetchRoles()
  },
  computed: {
    ...mapGetters({ listUsers: 'UsersStore/getListUsers' }),
    ...mapGetters({ totalUsers: 'UsersStore/getTotalUsers' }),
    ...mapGetters({ pageUserPagination: 'UsersStore/getPageUserPagination' })
  },
  watch: {},
  methods: {
    search(page = 1) {
      const { search, roleId, dateFrom, dateTo } = this.form
      console.log(this.form)
      this.$store.dispatch('UsersStore/fetchListUsers', {
        limit: 10,
        page,
        search,
        roleId,
        dateFrom,
        dateTo
      })
    },
    create() {
      this.$router.push({ path: '/users/user/create' })
    },
    updatePage(ev) {
      this.search(ev)
    },
    deleteUser(id) {
      const url = `/users/user/${id}`
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow('success', 'deleted')
          this.updatePage(this.pageUserPagination)
        })
        .catch(() => {
          this.alertShow('error', 'deleted')
        })
      this.closeModel()
    },
    blockedUser(id) {
      const url = `/users/user/ban/${id}`
      this.$api
        .patch(url)
        .then(() => {
          this.alertShow('success', 'banned')
          this.updatePage(this.pageUserPagination)
        })
        .catch(() => {
          this.alertShow('error', 'banned')
        })
      this.closeModel()
    },
    fetchRoles() {
      const url = '/roles'
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listRoles = data.map((el) => ({ value: el.id, label: el.name }))
        })
    }
  }
}
</script>
<style lang="scss">
.alert {
  position: fixed;
  width: 400px;
  z-index: 999;
  top: 20px;
  left: 50%;
}
.container {
  height: 100%;
  width: 100%;
  padding: 64px 16px 58px;

  .header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      color: #0f172a;
    }
    .route {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;

      color: #3e59e8;
    }
  }
  .block-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 30px;

    background: #ffffff;
    box-shadow: 0px 4px 101px rgba(88, 91, 66, 0.14);
    border-radius: 12px;
    .block-button {
      height: 58px;
      width: 100%;
    }
  }
  .tab-block {
    position: relative;
    width: 100%;
    border-radius: 25px;
  }
  .tab {
    border-radius: 12px;
    th {
      background-color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}
.disabled-link {
  color: #96a0ac;
  pointer-events: none;
}
.top20 {
  margin-top: 20px;
}
</style>
