import jwtDecode from 'jwt-decode'

export const AuthStore = {
  namespaced: true,
  state: () => ({
    token: window.localStorage.getItem('token') || null,
    admin: JSON.parse(window.localStorage.getItem('admin')) || null
  }),
  getters: {
    getToken(state) {
      return state.token
    },
    getAdmin(state) {
      return state.admin
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {
    async login({ commit }, params) {
      const url = '/auth/login'
      const body = { email: params.email, password: params.password }
      return this.$login
        .post(url, body)
        .then((res) => res.data.token)
        .then((token) => {
          commit('setToken', token)
          const parsToken = jwtDecode(token)
          localStorage.setItem('is_expanded', 'true')
          window.localStorage.setItem('token', token)
          window.localStorage.setItem('expires_at', parsToken.exp)
          window.localStorage.setItem('admin', JSON.stringify({ name: parsToken.name, id: parsToken.sub }))
          return token
        })
        .catch((err) => {
          console.log(err.response.status)
          return err.response
        })
    },
    logout({ commit }) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('is_expanded')
      window.localStorage.removeItem('adminId')
      window.localStorage.removeItem('expires_at')
      window.localStorage.removeItem('rememberMe')
      window.localStorage.removeItem('admin')
      window.localStorage.removeItem('roles')
      window.localStorage.removeItem('permissions')
      commit('setToken', null)
    },
    async getAccessToken() {
      const url = '/auth/token'
      await this.$api
        .get(url)
        .then((res) => res.data.token)
        .then((token) => {
          const parsToken = jwtDecode(token)
          localStorage.setItem('is_expanded', 'true')
          const rememberMe = JSON.parse(window.localStorage.getItem('rememberMe'))
          if (rememberMe) {
            window.localStorage.setItem('token', token)
            window.localStorage.setItem('expires_at', parsToken.exp)
            window.localStorage.setItem('admin', JSON.stringify({ name: parsToken.name, id: parsToken.sub }))
          }
          return token
        })
        .catch((err) => {
          console.log(err.response.status)
          return err.response
        })
    }
  }
}
