<template>
  <input
    type="checkbox"
    :id="id"
    :value="modelValue"
    :checked="isChecked"
    @change="emitUncheck($event.target.checked)"
    @input="$emit('update:modelValue', $event.target.value)"
    class="m-checkbox"
  />
  <label class="m-checkbox__label" :for="label">{{ label }}</label>
</template>
<script>
export default {
  name: "m-checkbox",
  data: () => {
    return {
      filter: {
        filterCollection: {}, // Object instead of array
      },
    };
  },
  props: {
    modelValue: { type: String, default: "" },
    isChecked: Boolean,
    label: { type: String },
    value: { type: Array },
    id: { type: Number },
  },
  computed: {},
  methods: {
    emitUncheck(event) {
      if (!event) {
        this.$emit("remove-selected-filter", this.id);
      }
    },
  },
};
</script>
<style lang="scss">
.m-checkbox {
  width: 20px;
  height: 20px;
  color: #404040;
  border-radius: 12px;
  margin-right: 20px;
  &__label {
    font-weight: 400;
    font-size: 18px;
  }
}
// .m-checkbox:hover {
//   border: 1px solid #b6c92a;
// }
</style>
