<template>
  <div class="container">
    <div class="header">
      <div class="title">Statistics</div>
    </div>
    <div class="block-cards">
      <CardComponent
        countTitle="Total users"
        countNumber="361"
        :imgUrl="require('@/assets/icons/users-card.svg')"
        filterImg="invert(33%) sepia(86%) saturate(316%) hue-rotate(175deg) brightness(90%) contrast(91%)"
        colorBord="#4E719F"
        colorButton="#3F6290"
        titleButton="All users"
      />
      <CardComponent
        countTitle="Online users"
        countNumber="120"
        :imgUrl="require('@/assets/icons/deal-card.svg')"
        filterImg="invert(53%) sepia(71%) saturate(326%) hue-rotate(99deg) brightness(83%) contrast(86%)"
        colorBord="#4DA97D"
        colorButton="#3D996D"
        titleButton="All users"
      />
      <CardComponent
        countTitle="Offline users"
        countNumber="647"
        :imgUrl="require('@/assets/icons/support-card.svg')"
        filterImg="invert(34%) sepia(8%) saturate(7299%) hue-rotate(326deg) brightness(87%) contrast(91%)"
        colorBord="#C04949"
        colorButton="#AC3535"
        titleButton="All users"
      />
    </div>
    <div class="block-content">
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="columns"
          :data="listStatistics"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardComponent from "@/components/dashbord/card";
import { h } from "vue";
import { NTag } from "naive-ui";
export default {
  name: "StatisticsPage",
  components: {
    CardComponent,
  },
  data: () => {
    return {
      columns: [
        {
          title: "Account id",
          key: "id",
        },
        {
          title: "Type of exchange",
          key: "type",
        },
        {
          title: "Status",
          key: "status",
          render(row) {
            const status = row.status;
            let colorBG = "";
            let colorText = "";
            if (status === "Processed") {
              colorBG = "#EDF6F2";
              colorText = "#4DA97D";
            } else if (status === "Canceled") {
              colorBG = "#FEEFF2";
              colorText = "#C04949";
            } else {
              colorBG = "#F1F5F9";
              colorText = "#64748B";
            }
            return h(
              NTag,
              {
                style: {
                  padding: "16px",
                  color: colorText,
                  background: colorBG,
                  fontSize: "14px",
                },
                round: true,
                bordered: false,
              },
              {
                default: () => {
                  return `${row.status}`;
                },
              }
            );
          },
        },
        {
          title: "Commision",
          key: "commision",
        },
        {
          title: "Exchange rate",
          key: "rate",
        },
      ],
      listStatistics: [],
    };
  },
};
</script>
<style lang="scss"></style>
