import { createRouter, createWebHistory } from "vue-router";
import Dashbord from "@/pages/Dashbord";
import Users from "@/pages/Users";
import Login from "@/pages/Login";
import Roles from "@/pages/Roles";
// import Support from "@/pages/Support";
import Currency from "@/pages/Currency";
import Deals from "@/pages/Deals";
import Transaction from "@/pages/Transaction";
import Deposit from "@/pages/Deposit";
// import Balance from "@/pages/Balance";
import PaymentMethod from "@/pages/PaymentMethod";
import Fee from "@/pages/Fee";
import Withdrawal from "@/pages/Withdrawal";
import Blog from "@/pages/Blog";
import ContentCategories from "@/pages/ContentCategories";
import StaticPages from "@/pages/StaticPages";
import FAQCategories from "@/pages/FAQCategories";
import FAQQuestions from "@/pages/FAQQuestions";
import Languages from "@/pages/Languages";
import Statistics from "@/pages/Statistics";
import RolesCreate from "@/pages/RolesCreate";
// import SupportAnswer from "@/pages/SupportAnswer";
import CurrencyCreate from "@/pages/CurrencyCreate";
import DepositEdit from "@/pages/DepositEdit";
import PaymentMethodCreate from "@/pages/PaymentMethodCreate";
import PaymentMethodEdit from "@/pages/PaymentMethodEdit";
import FeeCreate from "@/pages/FeeCreate";
import BlogCreate from "@/pages/BlogCreate";
import ContentCategoriesCreate from "@/pages/ContentCategoriesCreate";
import StaticPagesCreate from "@/pages/StaticPagesCreate";
import FAQCategoriesCreate from "@/pages/FAQCategoriesCreate";
import FAQQuestionsCreate from "@/pages/FAQQuestionsCreate";
import LanguagesCreate from "@/pages/LanguagesCreate";
import UserCreate from "@/pages/UserCreate";
import UserEdit from "@/pages/UsersEdit";
import StaticPagesEdit from "@/pages/StaticPagesEdit";
import BlogEdit from "@/pages/BlogEdit";
import CurrencyEdit from "@/pages/CurrencyEdit";
import DealsInfo from "@/pages/DealsInfo";
import FeeEditPage from "@/pages/FeeEditPage.vue";
import FeeWithdrawalsEdit from "@/pages/FeeWithdrawalsEdit.vue";
import FeeWithdrawalsCreate from "@/pages/FeeWithdrawalsCreate.vue";
import RolesEdit from "@/pages/RolesEditPage.vue";
import NotFound from "@/pages/NotFound.vue";

import { authGuard, accessGuard } from "@/guards/AuthGuard";
import FAQQuestionsEdit from "@/pages/FAQQuestionsEdit";
import FAQCategoriesEdit from "@/pages/FAQCategoriesEdit";

const routes = [
  {
    path: "/",
    component: Dashbord,
    name: "dashbord",
    meta: { permission: "dashbord" },
    beforeEnter: [authGuard],
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound,
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/users",
    component: Users,
    name: "users",
    meta: { permission: "users-user-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/users/user/create",
    component: UserCreate,
    name: "user-create",
    meta: { permission: "users-user-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/users/user/:id/edit",
    component: UserEdit,
    name: "user-edit",
    props: true,
    meta: { permission: "users-user-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/statistics",
    component: Statistics,
    name: "statistics",
    meta: { permission: "users-statistic-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/roles",
    component: Roles,
    name: "roles",
    meta: { permission: "users-roles-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/roles/create",
    component: RolesCreate,
    name: "roles-create",
    meta: { permission: "users-roles-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/roles/:id/edit",
    component: RolesEdit,
    props: true,
    name: "roles-edit",
    meta: { permission: "users-roles-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // {
  //   path: "/support",
  //   component: Support,
  //   name: "support",
  //   meta: { permission: "users-support-page" },
  //   beforeEnter: [authGuard, accessGuard],
  // },
  // {
  //   path: "/support/answer",
  //   component: SupportAnswer,
  //   name: "support-answer",
  //   meta: { permission: "users-support-page" },
  //   beforeEnter: [authGuard, accessGuard],
  // },
  // currency
  {
    path: "/currency",
    component: Currency,
    name: "currency",
    meta: { permission: "market-currency-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/currency/create",
    component: CurrencyCreate,
    name: "currency-create",
    meta: { permission: "market-currency-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/currency/:id/edit",
    component: CurrencyEdit,
    name: "currency-edit",
    props: true,
    meta: { permission: "market-currency-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // deals
  {
    path: "/deals",
    component: Deals,
    name: "deals",
    meta: { permission: "market-deals-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/deals/:id/info",
    component: DealsInfo,
    props: true,
    name: "deal-info",
    meta: { permission: "market-deals-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/transaction",
    component: Transaction,
    name: "transaction",
    meta: { permission: "market-transaction-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/deposit",
    component: Deposit,
    name: "deposit",
    meta: { permission: "finance-deposit-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/deposit/:id/edit",
    component: DepositEdit,
    props: true,
    name: "deposit-edit",
    meta: { permission: "finance-deposit-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // {
  //   path: "/balance",
  //   component: Balance,
  //   name: "balance",
  //   beforeEnter: authGuard,
  // },
  // limits
  {
    path: "/payment-method",
    component: PaymentMethod,
    name: "payment-method",
    meta: { permission: "finance-payment-methods-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/payment-method/create",
    component: PaymentMethodCreate,
    name: "payment-method-create",
    meta: { permission: "finance-payment-methods-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/payment-method/:id/edit",
    component: PaymentMethodEdit,
    props: true,
    name: "payment-method-edit",
    meta: { permission: "finance-payment-methods-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // fee
  {
    path: "/fee",
    component: Fee,
    name: "fee",
    meta: { permission: "finance-fees-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/fee/create",
    component: FeeCreate,
    name: "fee-create",
    meta: { permission: "finance-fees-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/fee/:id/edit",
    component: FeeEditPage,
    props: true,
    name: "fee-edit",
    meta: { permission: "finance-fees-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/fee/withdrawals/create",
    component: FeeWithdrawalsCreate,
    name: "fee-withdrawals-create",
    meta: { permission: "finance-fees-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/fee/withdrawals/:id/edit",
    component: FeeWithdrawalsEdit,
    props: true,
    name: "fee-withdrawals-edit",
    meta: { permission: "finance-fees-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // withdrawal
  {
    path: "/withdrawal",
    component: Withdrawal,
    name: "withdrawal",
    meta: { permission: "finance-withdrawals-page" },
    beforeEnter: [authGuard, accessGuard],
  },

  // content

  {
    path: "/content/blog",
    component: Blog,
    name: "blog",
    meta: { permission: "other-content-blog-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/blog/create",
    component: BlogCreate,
    name: "blog-create",
    meta: { permission: "other-content-blog-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/blog/:id/edit",
    component: BlogEdit,
    props: true,
    name: "blog-edit",
    meta: { permission: "other-content-blog-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/categories",
    component: ContentCategories,
    name: "categories",
    meta: { permission: "other-content-categories-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/categories/create",
    component: ContentCategoriesCreate,
    name: "contentCategoriesCreate",
    meta: { permission: "other-content-categories-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/static-pages",
    component: StaticPages,
    name: "content-static-pages",
    meta: { permission: "other-content-static-pages-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/static-pages/create",
    component: StaticPagesCreate,
    name: "content-static-pages-create",
    meta: { permission: "other-content-static-pages-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/content/static-pages/:id/edit",
    component: StaticPagesEdit,
    props: true,
    name: "content-static-pages-edit",
    meta: { permission: "other-content-static-pages-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // faq
  {
    path: "/faq/categories",
    component: FAQCategories,
    name: "faq",
    meta: { permission: "other-faq-categories-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/faq/categories/create",
    component: FAQCategoriesCreate,
    name: "faq-categories-create",
    meta: { permission: "other-faq-categories-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/faq/categories/:id/edit",
    component: FAQCategoriesEdit,
    name: "faq-categories-edit",
    props: true,
    meta: { permission: "other-faq-categories-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/faq/questions",
    component: FAQQuestions,
    name: "faq-questions",
    meta: { permission: "other-faq-questions-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/faq/questions/create",
    component: FAQQuestionsCreate,
    name: "faq-questions-create",
    meta: { permission: "other-faq-questions-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/faq/questions/:id/edit",
    component: FAQQuestionsEdit,
    props: true,
    name: "faq-questions-edit",
    meta: { permission: "other-faq-questions-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  // languages
  {
    path: "/languages",
    component: Languages,
    name: "languages",
    meta: { permission: "languages-page" },
    beforeEnter: [authGuard, accessGuard],
  },
  {
    path: "/languages/create",
    component: LanguagesCreate,
    name: "Languages-create",
    meta: { permission: "languages-page" },
    beforeEnter: [authGuard, accessGuard],
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

export default router;
