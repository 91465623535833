<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Fee</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Fee</router-link>
      </div>
    </div>
    <n-card>
      <n-tabs type="line" animated>
        <n-tab-pane
          style="padding: 0"
          name="Payment Method"
          tab="Payment Method"
        >
          <div class="block-content reverse">
            <m-button
              :imgUrl="require('@/assets/icons/plus-button.svg')"
              widthButton="148px"
              @click="create"
            >
              <span>Create</span>
            </m-button>

            <div class="tab-block">
              <n-data-table
                class="tab"
                :columns="
                  createColumns(openModal, goToRoute, activeCheckboxFee)
                "
                :data="list"
                :single-line="false"
              />
              <n-pagination
                class="top20"
                :item-count="total"
                size="large"
                :default-page-size="paramsFilter.limit"
                @update:page="updatePage"
              >
              </n-pagination>
            </div>
          </div>
        </n-tab-pane>
        <n-tab-pane style="padding: 0" name="Withdrawals" tab="Withdrawals">
          <div class="block-content reverse">
            <m-button
              :imgUrl="require('@/assets/icons/plus-button.svg')"
              widthButton="148px"
              @click="createFeeWithdrawals"
            >
              <span>Create</span>
            </m-button>

            <div class="tab-block">
              <n-data-table
                class="tab"
                :columns="
                  createColumnsWithdrawals(
                    openModal,
                    goToRoute,
                    activeCheckboxFeeWithdrawals
                  )
                "
                :data="listWithdrawals"
                :single-line="false"
              />
              <n-pagination
                class="top20"
                :item-count="total"
                size="large"
                :default-page-size="paramsFilter.limit"
                @update:page="updatePage"
              >
              </n-pagination>
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </div>
  <m-modal
    :open="showModalDelete"
    :title="modelTitle"
    :subtitle="modelSubtitle"
    :nameButtonActive="nameButtonActive"
    @cancel-event="closeModel"
    @active-event="deleteFee(deleteId)"
  ></m-modal>
</template>
<script>
import { h } from "vue";
import { NButton, NCheckbox } from "naive-ui";
export default {
  name: "FeePage",
  data: () => {
    return {
      createColumns: (openModal, goToRoute, activeCheckboxFee) => {
        return [
          {
            title: "ID",
            key: "id",
          },
          {
            title: "Payment method",
            key: "paymentMethod.name",
          },
          {
            title: "Fee of buy",
            key: "feeOfBuy",
          },
          {
            title: "Fee of sell",
            key: "feeOfSell",
          },
          {
            title: "Active",
            key: "isActive",
            render(row) {
              return h(NCheckbox, {
                checked: row.isActive,
                size: "large",
                onClick: () => {
                  row.isActive = !row.isActive;
                  activeCheckboxFee(row);
                },
              });
            },
          },
          {
            title: "Action",
            key: "actions",
            className: "textTop",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("fee-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      createColumnsWithdrawals: (
        openModal,
        goToRoute,
        activeCheckboxFeeWithdrawals
      ) => {
        return [
          {
            title: "ID",
            key: "id",
          },
          {
            title: "Coin",
            key: "coin",
          },
          {
            title: "Wallet",
            key: "wallet",
          },
          {
            title: "0$-9.99$",
            key: "feeOfLess10$",
          },
          {
            title: "10$-19.99$",
            key: "feeOfFrom10To20$",
          },
          {
            title: "20$+",
            key: "feeOfMore20$",
          },
          {
            title: "Active",
            key: "isActive",
            render(row) {
              return h(NCheckbox, {
                checked: row.isActive,
                size: "large",
                onClick: () => {
                  row.isActive = !row.isActive;
                  activeCheckboxFeeWithdrawals(row);
                },
              });
            },
          },
          {
            title: "Action",
            key: "actions",
            className: "textTop",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("fee-withdrawals-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id, "withdrawals"),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      list: [],
      listWithdrawals: [],
      paramsFilter: {
        page: 1,
        limit: 10,
      },
      total: null,
      tab: "",
    };
  },
  mounted() {
    this.fetchListFees();
    this.fetchListFeesWithdrawals();
  },
  methods: {
    fetchListFees() {
      const url = "/fees";
      this.$api
        .get(url, {
          params: this.paramsFilter,
        })
        .then((res) => res.data)
        .then((data) => {
          this.list = data.fees;
          this.total = data.total;
        });
    },
    fetchListFeesWithdrawals() {
      const url = "fees/list/withdrawals";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listWithdrawals = data.fees;
          this.totalWithdrawals = data.total;
        });
    },
    create() {
      this.$router.push({ name: "fee-create" });
    },
    openModal(type, id, tab) {
      if (type === "block") {
        this.modelTitle = "Block";
        this.modelSubtitle = `Are you sure you want to blocked id:${id}?`;
        this.nameButtonActive = "Block";
        this.showModalBlock = true;
        this.deleteId = id;
      } else if (type === "delete") {
        this.modelTitle = "Delete";
        this.modelSubtitle = `Are you sure you want to delete id:${id}?`;
        this.nameButtonActive = "Delete";
        this.showModalDelete = true;
        this.tab = tab;
        this.deleteId = id;
      }
      this.showModal = true;
    },
    deleteFee(deleteId) {
      let url;
      if (this.tab === "withdrawals") {
        url = `/fees/withdrawals/${deleteId}`;
      } else {
        url = `/fees/${deleteId}`;
      }
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow("success", "Fee deleted");
          this.closeModel();
          this.fetchListFees();
          this.fetchListFeesWithdrawals();
        })
        .catch(() => {
          this.alertShow("error", "Fee not deleted");
          this.closeModel();
        });
    },
    activeCheckboxFee(obj) {
      const url = `/fees/${obj.id}`;
      this.$api
        .put(url, obj)
        .then(() => {
          this.alertShow("success", "Fee update");
        })
        .catch(() => {
          this.alertShow("error", "Fee not update");
        });
    },
    activeCheckboxFeeWithdrawals(obj) {
      const url = `/fees/withdrawals/${obj.id}`;
      this.$api
        .put(url, obj)
        .then(() => {
          this.alertShow("success", "Fee withdrawals update");
        })
        .catch(() => {
          this.alertShow("error", "Fee withdrawals not update");
        });
    },
    updatePage(page) {
      this.paramsFilter.page = page;
      this.fetchListFee();
    },
    createFeeWithdrawals() {
      this.$router.push({ name: "fee-withdrawals-create" });
    },
  },
};
</script>
<style lang=""></style>
