<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Currency</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/currency">Currency</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <n-tabs type="line" animated size="large">
        <n-tab-pane name="main" tab="Main">
          <div class="tabs-content">
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Name</span>
              </div>
              <m-input placeholder="Name" v-model="form.nameCurrency" />
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Code</span>
              </div>
              <m-input
                @blur="v$.form.code.$touch"
                placeholder="Code"
                v-model="form.code"
                :isError="v$.form.code.$error"
                textError="Invalid code currency"
              />
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Is Active</span>
              </div>
              <div class="width100">
                <n-space>
                  <n-switch v-model:value="form.isActive" />
                </n-space>
              </div>
            </div>
          </div>
        </n-tab-pane>
        <n-tab-pane name="logo" tab="Logo">
          <div class="tabs-content">
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Logo web</span>
              </div>
              <div class="width100">
                <m-upload name="imageWeb" v-model="imageWeb"> </m-upload>
              </div>
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Logo app</span>
              </div>
              <div class="width100">
                <m-upload name="imageApp" v-model="imageApp"> </m-upload>
              </div>
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="createCurrency()">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import cc from "currency-codes";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CurrencyCreatePage",
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      imageWeb: "",
      imageApp: "",
      form: {
        nameCurrency: "",
        code: "",
        isActive: false,
      },
      options: [
        {
          label: "BTC/USDT",
          value: "1",
        },
      ],
    };
  },
  validations() {
    return {
      form: {
        code: {
          required,
          function(value) {
            return this.validCodeCurrency(value);
          },
        },
      },
    };
  },
  methods: {
    validCodeCurrency(value) {
      if (cc.code(value)) {
        return true;
      } else {
        return false;
      }
    },
    async createCurrency() {
      await this.v$.form.$touch();
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const url = `/currency`;
        const formData = new FormData();
        if (this.imageWeb) {
          formData.append("files", this.imageWeb, "imageWeb");
        }
        if (this.imageApp) {
          formData.append("files", this.imageApp, "imageApp");
        }

        for (let el in this.form) {
          formData.append(el, this.form[el]);
        }

        this.$api
          .post(url, formData)
          .then(() => {
            this.alertShow("success", "Currency create");
          })
          .catch(() => {
            this.alertShow("error", "Currency not create");
          });
      }
    },
  },
};
</script>
<style lang="scss">
.col-title-checkbox {
  width: 182px;
}
.tabs-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}
.width100 {
  width: 100%;
}
</style>
