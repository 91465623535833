<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Roles</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/roles">Roles</router-link>/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Name role</span>
        </div>
        <m-input placeholder="Name role" v-model="form.name" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isActive" />
          </n-space>
        </div>
      </div>
      <div class="block-checkbox">
        <div class="permissions-title">
          <span class="subtitle">Permissions</span>
        </div>
        <div class="permissions-content">
          <button
            class="subtitle blue"
            style="margin-bottom: 40px"
            @click="allSelect"
          >
            Check/uncheck all
          </button>
          <div class="content-check__block">
            <n-checkbox-group v-model:value="form.permissions" size="large">
              <n-space item-style="display: flex;" vertical>
                <n-checkbox
                  :value="item.value"
                  :label="item.label"
                  v-for="(item, index) in listPermissions"
                  :key="index"
                />
              </n-space>
            </n-checkbox-group>
          </div>
        </div>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updateRole">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RolesEditPage",
  data: () => {
    return {
      listPermissions: [],
      form: {
        name: "",
        permissions: [],
        isActive: true,
      },
    };
  },
  props: {
    id: {
      type: Number,
    },
  },
  mounted() {
    this.fetchPermisions();
    this.fetchRoleById();
  },
  methods: {
    updateRole() {
      const url = `/roles/${this.id}`;
      this.$api
        .put(url, this.form)
        .then((res) => res.data)
        .then(() => this.alertShow("success", "Role update"))
        .catch((err) => {
          console.log(err);
          this.alertShow("error", "Role not update");
        });
    },
    removeSelectedFilter(index) {
      delete this.form.permissions[index];
    },
    isChecked(index) {
      return !!this.form.permissions[index];
    },
    allSelect() {
      this.form.permissions = this.form.permissions.length
        ? []
        : this.listPermissions.map((el) => el.value);
    },
    fetchRoleById() {
      const url = `/roles/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.form = data;
        });
    },
    fetchPermisions() {
      const url = "/roles/permissions";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listPermissions;
          for (const el in data) {
            this.listPermissions.push({ label: el, value: data[el] });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.textTop {
  vertical-align: top;
}
.block-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-title {
  width: 200px;
}
.block-checkbox {
  display: flex;
  margin-bottom: 20px;
  .permissions-title {
    padding-right: 95px;
  }
}
.ul-style {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}
.content-block__button {
  display: flex;
  justify-content: end;
  gap: 10px;
  .btn_cancel {
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 32px;
    &:hover {
      background: #eeeeee;
    }
  }
}
</style>
