<template>
  <div class="login">
    <div class="block">
      <div class="logo">
        <img class="logo_img" src="../assets/images/Logo.svg" alt="Logo" />
      </div>
      <h2 class="subtitle">Sign in to start your session</h2>
      <div class="block_form">
        <div class="error">{{ errorMessage }}</div>
        <div class="input-block">
          <input
            v-model="form.email"
            @blur="v$.form.email.$touch"
            class="block_form--input"
            type="email"
            placeholder="Email"
          />
          <div class="error" v-if="v$.form.email.$error">Invalid email</div>
        </div>
        <div class="input-block">
          <input v-model="form.password" class="block_form--input" type="password" placeholder="Password" />
          <div class="error" v-if="v$.form.password.$error">
            Invalid password. Password must be between 8 and 15 characters
          </div>
        </div>
        <div class="block_remember">
          <input v-model="form.rememberMe" type="checkbox" class="block_remember--checkbox" />
          <span>Remember me</span>
        </div>
      </div>
      <m-button @click="SignIn">Sign in</m-button>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import {required, minLength, maxLength, email} from '@vuelidate/validators'
export default {
  name: 'LoginPage',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: false
      },
      errorMessage: ''
    }
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(15)
        }
      }
    }
  },
  mounted() {
    // localStorage.setItem("is_expanded", "");
  },
  methods: {
    async SignIn() {
      await this.v$.form.$touch()
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        await this.$store.dispatch('AuthStore/login', this.form).then(async (res) => {
          if (res.status === 403) {
            this.errorMessage = res.data.message
          } else if (res.status === 404) {
            this.errorMessage = res.data.message
          } else {
            window.localStorage.setItem('rememberMe', this.form.rememberMe)
            await this.$store.commit('UsersStore/isAuthenticated', true)
            await this.$store.dispatch('UsersStore/fetchUserRoles')
            await this.$router.push({ path: '/' })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.login {
  width: 100%;
  height: 100vh;
  display: flex;

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;
    width: 550px;
    background: #ffffff;
    box-shadow: 0px 4px 101px rgba(88, 91, 66, 0.14);
    border-radius: 12px;
    margin: auto auto;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      &_img {
      }
      &_text {
        padding-top: 20px;
      }
    }
    .input-block {
      margin-bottom: 20px;
    }
    .block_form--input {
      padding: 16px 14px;

      width: 470px;
      height: 56px;

      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 12px;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-size: 16px;
      line-height: 24px;
    }
    &_remember {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;

      &--checkbox {
        width: 24px;
        height: 24px;
        border: 1px solid #3e4659;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: 8px;
      }
    }
    .error {
      color: red;
      margin-left: 10px;
    }
  }
  .subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
