<template>
  <div class="form-search">
    <div v-if="dateShow" class="grow">
      <label for="start">Date from</label>
      <input
        type="date"
        id="start"
        name="trip-start"
        placeholder="Date from"
        @change="$emit('update:dateFrom', $event.target.value)"
      />
    </div>
    <div v-if="dateShow" class="grow">
      <label for="finish">Date to</label>
      <input
        type="date"
        id="finish"
        name="trip-finish"
        placeholder="Date to"
        @change="$emit('update:dateTo', $event.target.value)"
      />
    </div>

    <div v-if="selectShow" class="grow" style="width: 150px">
      <label for="role">Role</label>
      <n-select id="role" placeholder="Select role" v-model:value="selectRole" :options="listRoles" />
    </div>

    <div v-if="selectShowStatus" class="grow" style="width: 150px">
      <label for="status">Status</label>
      <n-select id="status" placeholder="Select status" v-model:value="selectStatus" :options="listStatus" />
    </div>

    <div class="grow" v-if="showSearch">
      <label for="search">Search</label>
      <m-input id="search" @change="$emit('update:search', $event.target.value)"></m-input>
    </div>

    <div class="grow" v-if="showCustomInput">
      <label for="custom">{{ customInputName }}</label>
      <m-input
        id="custom"
        :placeholder="customPlaceholder"
        @change="$emit('update:custom', $event.target.value)"
      ></m-input>
    </div>

    <m-button
      style="margin-top: 22px"
      :imgUrl="require('@/assets/icons/search-button.svg')"
      @click="$emit('search', 1)"
    ></m-button>
  </div>
</template>
<script>
export default {
  name: 'm-form-search',
  data: () => {
    return {
      selectStatus: null,
      selectRole: null
    }
  },
  props: {
    selectShow: {
      type: Boolean,
      default: false
    },
    showCustomInput: {
      type: Boolean,
      default: false
    },
    customInputName: {
      type: String,
      default: 'Fiend'
    },
    customPlaceholder: {
      type: String,
      default: 'Enter text'
    },
    selectShowStatus: {
      type: Boolean,
      default: false
    },
    dateShow: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Enter text'
    },
    nameModel: {
      type: String,
      default: 'value'
    },
    options: {
      type: Array
    },
    listStatus: {
      type: Array
    },
    listRoles: {
      type: Array
    }
  },
  watch: {
    selectStatus() {
      this.$emit('update:status', this.selectStatus)
    },
    selectRole() {
      this.$emit('update:roleId', this.selectRole)
    }
  },
  methods: {
    updateSelectStatus() {
      console.log('tytt')
    }
  }
}
</script>
<style lang="scss">
.form-search {
  display: flex;
  justify-content: space-between;
  //   align-items: center;
  gap: 20px;
  .grow {
    flex-grow: 1;
  }
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 14px;
    gap: 10px;
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
  }
}
</style>
