<template>
  <div class="container">
    <div class="header">
      <div class="title">Languages</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/languages">Languages</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Locale</span>
        </div>
        <m-input placeholder="Locale" v-model="form" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Title</span>
        </div>
        <m-input placeholder="Title" v-model="form" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Sort</span>
        </div>
        <m-input placeholder="Sort" v-model="form" />
      </div>

      <div class="block-input">
        <div class="col-title"></div>
        <div class="width100">
          <n-checkbox size="large" value="item" label="Is default" />
        </div>
      </div>

      <div class="block-input">
        <div class="col-title"></div>
        <div class="width100">
          <n-checkbox size="large" value="item" label="Active" />
        </div>
      </div>

      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
