<template>
  <div class="container">
    <div class="header">
      <div class="title">Deals</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Deals</router-link>
      </div>
    </div>
    <!-- <div class="block-cards">
      <CardComponent
        countTitle="Total deals"
        countNumber="23"
        :imgUrl="require('@/assets/icons/arrow.left.arrow.right.svg')"
        filterImg="invert(33%) sepia(86%) saturate(316%) hue-rotate(175deg) brightness(90%) contrast(91%)"
        colorBord="#4E719F"
        colorButton="#3F6290"
        titleButton="Open"
      />
      <CardComponent
        countTitle="Total completed deals"
        countNumber="120"
        :imgUrl="require('@/assets/icons/arrow.left.arrow.right.svg')"
        filterImg="invert(53%) sepia(71%) saturate(326%) hue-rotate(99deg) brightness(83%) contrast(86%)"
        colorBord="#4DA97D"
        colorButton="#3D996D"
        titleButton="Open"
      />
      <CardComponent
        countTitle="Total canceled deals"
        countNumber="6"
        :imgUrl="require('@/assets/icons/arrow.left.arrow.right.svg')"
        filterImg="invert(34%) sepia(8%) saturate(7299%) hue-rotate(326deg) brightness(87%) contrast(91%)"
        colorBord="#C04949"
        colorButton="#AC3535"
        titleButton="Open"
      />
    </div> -->
    <div class="block-content">
      <m-form-search
        :selectShowStatus="true"
        :showCustomInput="true"
        customInputName="Deal Id"
        customPlaceholder="Deal id"
        v-model:status="paramsFilter.status"
        v-model:dateFrom="paramsFilter.dateFrom"
        v-model:dateTo="paramsFilter.dateTo"
        v-model:custom="paramsFilter.dealId"
        @search="search"
        :listStatus="listStatus"
        :showSearch="false"
      ></m-form-search>
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColums(openModalModerator, enumDealsStatus, page, paramsFilter.limit)"
          :data="list"
          :single-line="false"
        />
        <n-pagination
          class="top20"
          :item-count="total"
          size="large"
          :default-page-size="paramsFilter.limit"
          @update:page="updatePage"
        >
        </n-pagination>
      </div>
    </div>
  </div>
  <div v-if="openModal" class="modal">
    <div class="modal_header">
      <span class="modal_title">Connect to deal</span>
      <button type="button" class="btn-close" @click="closeModal">
        <img src="@/assets/icons/modal_close.svg" alt="X" />
      </button>
    </div>
    <div class="modal_subtitle">You want to connect to the deal : {{ dealId }}?</div>
    <div class="modal_button">
      <m-button widthButton="98px" class="cancel" @click="closeModal">Cancel</m-button>
      <m-button widthButton="98px" @click="connectModerator(dealId)">Connect</m-button>
    </div>
  </div>
</template>
<script>
import { h } from 'vue'
import { NTag, NButton } from 'naive-ui'
import { mapGetters } from 'vuex'
// import CardComponent from "@/components/dashbord/card";
export default {
  name: 'DealsPage',
  components: {
    // CardComponent,
  },
  computed: {
    ...mapGetters({ admin: 'AuthStore/getAdmin' })
  },
  data: () => {
    return {
      createColums: (openModalModerator, enumDealsStatus, page, limit) => {
        return [
          {
            title: 'Id',
            key: 'id',
            render: (_, index) => {
              return `${index + 1 + (page - 1) * limit}`
            }
          },

          {
            title: 'Deal ID',
            key: 'id'
          },
          {
            title: 'Status',
            key: 'status',
            render(row) {
              const status = row.status
              let colorBG = ''
              let colorText = ''
              if (status === 'dispute_open' || status === 'deal_completed') {
                colorBG = '#EDF6F2'
                colorText = '#4DA97D'
              } else if (status === 'deal_canceled') {
                colorBG = '#FEEFF2'
                colorText = '#C04949'
              } else {
                colorBG = '#F1F5F9'
                colorText = '#64748B'
              }
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: colorText,
                    background: colorBG,
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    return `${enumDealsStatus[status]}`
                  }
                }
              )
            }
          },
          {
            title: 'Side',
            key: 'side',
            render(row) {
              const status = row.side
              let colorBG = ''
              let colorText = ''
              if (status.toLowerCase() === 'buy') {
                colorBG = '#EDF6F2'
                colorText = '#4DA97D'
              } else if (status.toLowerCase() === 'sell') {
                colorBG = '#FEEFF2'
                colorText = '#C04949'
              } else {
                colorBG = '#F1F5F9'
                colorText = '#64748B'
              }
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: colorText,
                    background: colorBG,
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    return `${row.side}`
                  }
                }
              )
            }
          },
          {
            title: 'Responsible',
            key: 'responsible',
            render(row) {
              const status = row.status
              let colorBG = '#ffffff'
              let colorText = ''
              let text = ''
              if (status === 'dispute_open') {
                if (!row.responsible) {
                  text = <span class="ling">Need a moderator</span>
                } else {
                  text = <span>{row.responsible.name}</span>
                }
              } else {
                text = <span>{row?.responsible?.name}</span>
              }
              return h(
                NTag,
                {
                  style: {
                    padding: '16px',
                    color: colorText,
                    background: colorBG,
                    fontSize: '14px'
                  },
                  round: true,
                  bordered: false
                },
                {
                  default: () => {
                    return text
                  }
                }
              )
            }
          },
          {
            title: 'Pair',
            key: 'pair',
            render(row) {
              return `${row.order.coin}/${row.order.currency}`
            }
          },
          {
            title: 'Price',
            key: 'price'
          },
          {
            title: 'Quantity',
            key: 'quantity'
          },
          {
            title: 'Data Created',
            key: 'createdAt',
            render(row) {
              const date = new Date(row.createdAt)
              const year = date.getFullYear()
              const day = date.getDate()
              const correctDay = day < 10 ? `0${day}` : day
              const mount = date.getMonth() + 1
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const seconds = date.getSeconds()
              const correctMount = mount < 10 ? `0${mount}` : mount
              return `${correctDay}.${correctMount}.${year} ${hours}:${minutes}:${seconds}`
            }
          },
          {
            title: 'Action',
            key: 'actions',
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: '#E2F54B',
                      padding: '4px',
                      width: '32px',
                      height: '32px',
                      'border-radius': '8px'
                    },
                    size: 'large',
                    quaternary: true,
                    vertical: false,
                    onClick: () => openModalModerator(row)
                  },
                  {
                    default: () => {
                      return h('img', {
                        src: require('@/assets/icons/chats-contact.svg'),
                        alt: 'edit'
                      })
                    }
                  }
                )
              ]
            }
          }
        ]
      },
      list: [],
      listStatus: [
        {
          label: 'Deal open',
          value: 'deal_open'
        },
        {
          label: 'Money sent',
          value: 'money_sent'
        },
        {
          label: 'Dispute open',
          value: 'dispute_open'
        },
        {
          label: 'Deal canceled',
          value: 'deal_canceled'
        },
        {
          label: 'Deal completed',
          value: 'deal_completed'
        }
      ],
      enumDealsStatus: {
        deal_open: 'Deal is open',
        money_sent: 'Money sent',
        dispute_open: 'Dispute is open',
        deal_canceled: 'Canceled',
        deal_completed: 'Completed'
      },
      paramsFilter: {
        page: 1,
        limit: 10,
        dealId: '',
        status: '',
        dateFrom: '',
        dateTo: ''
      },
      page: null,
      total: null,
      openModal: false,
      dealId: null
    }
  },
  mounted() {
    this.fetchListOrders()
  },
  methods: {
    connectModerator(dealId) {
      console.log('connect', dealId)
      const url = `/deals/add-moderator/`
      this.$api
        .patch(url, {
          dealId: dealId
        })
        .then((res) => {
          return res.data
        })
        .then(() => {
          this.closeModal()
          this.goToRoute('deal-info', dealId)
        })
        .catch((err) => {
          console.log(err)
          this.closeModal()
        })
    },
    openModalModerator(row) {
      if (row.status === 'dispute_open' && !row.responsibleId) {
        this.dealId = row.id
        this.openModal = true
      } else {
        this.goToRoute('deal-info', row.id)
      }
    },
    closeModal() {
      this.openModal = false
      this.dealId = null
    },
    fetchListOrders() {
      const url = '/deals'
      if (this.paramsFilter.dateTo) {
        const date = new Date(this.paramsFilter.dateTo)
        this.paramsFilter.dateTo = new Date(date.setDate(date.getDate() + 1)).toJSON().split('T')[0]
      }
      this.$api
        .get(url, {
          params: this.paramsFilter
        })
        .then((res) => {
          return res.data
        })
        .then((data) => {
          this.list = data.deals
          this.page = data.page
          this.total = data.total
        })
    },
    updatePage(page) {
      this.paramsFilter.page = page
      this.fetchListOrders()
    },
    search() {
      this.fetchListOrders()
    }
  }
}
</script>
<style lang="scss">
.ling {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 20px;
    background: red;
    top: 7px;
    left: -10px;
  }
}
</style>
