<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Blog</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/content/blog">Blog</router-link>/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <n-tabs type="card">
        <n-tab-pane name="en" tab="Eng" class="tab-container">
          <!-- Header -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Header</span>
            </div>
            <div class="width100">
              <m-input :value="form.header" v-model="form.header"></m-input>
            </div>
          </div>
          <!-- Slug -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Slug</span>
            </div>
            <div class="width100">
              <m-input :value="form.slug" v-model="form.slug"></m-input>
            </div>
          </div>
          <!-- Slug -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Preview</span>
            </div>
            <div class="width100">
              <m-input :value="form.slug" v-model="form.preview"></m-input>
            </div>
          </div>
          <!-- Content -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Content</span>
            </div>
            <div class="width100">
              <QuillEditor
                :content="form.content"
                contentType="html"
                toolbar="minimal"
                theme="snow"
                v-model:content="form.content"
                :key="keyContent"
              />
            </div>
          </div>
          <!-- Main photo -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Main photo</span>
            </div>
            <div class="width100">
              <img
                v-if="form.imageMainUrl && form.imageMainUrl !== 'null'"
                :src="form.imageMainUrl"
                alt=""
                width="600"
                height="500"
              />
              <m-button
                v-if="form.imageMainUrl && form.imageMainUrl !== 'null'"
                style="margin-top: 20px"
                @click="deleteImage('imageMainUrl')"
                >Delete image</m-button
              >
              <m-upload v-else v-model="imageMain" style="margin-top: 20px">
              </m-upload>
            </div>
          </div>
          <!-- Main photo alt -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Image main alt</span>
            </div>
            <div class="width100">
              <m-input
                :value="form.imageMainAlt"
                v-model="form.imageMainAlt"
              ></m-input>
            </div>
          </div>
          <!-- Long photo -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Long photo</span>
            </div>
            <div class="width100">
              <img
                v-if="form.imageLongUrl && form.imageLongUrl !== 'null'"
                :src="form.imageLongUrl"
                alt=""
                width="600"
                height="500"
              />
              <m-button
                v-if="form.imageLongUrl && form.imageLongUrl !== 'null'"
                style="margin-top: 20px"
                @click="deleteImage('imageLongUrl')"
                >Delete image</m-button
              >
              <m-upload v-else v-model="imageLong" style="margin-top: 20px">
              </m-upload>
            </div>
          </div>
          <!-- Long photo alt -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Image long alt</span>
            </div>
            <div class="width100">
              <m-input
                :value="form.imageLongAlt"
                v-model="form.imageLongAlt"
              ></m-input>
            </div>
          </div>

          <!-- Category -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Category</span>
            </div>
            <div class="width100">
              <n-space vertical>
                <n-select
                  v-model:value="form.articleThemeId"
                  :options="options"
                />
              </n-space>
            </div>
          </div>

          <!-- Time reading -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Time reading (minutes)</span>
            </div>
            <m-input
              type="number"
              v-model="form.timeToRead"
              :value="form.timeToRead"
              placeholder="Time reading (minutes)"
            />
          </div>

          <!-- Meta title -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Meta title</span>
            </div>
            <div class="width100">
              <m-input
                :value="form.metaTitle"
                v-model="form.metaTitle"
              ></m-input>
            </div>
          </div>

          <!-- Meta description -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Meta description</span>
            </div>
            <div class="width100">
              <m-input
                :value="form.metaDescription"
                v-model="form.metaDescription"
              ></m-input>
            </div>
          </div>

          <!-- Meta keywords -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Meta keywords</span>
            </div>
            <div class="width100">
              <m-input
                :value="form.metaKeywords"
                v-model="form.metaKeywords"
              ></m-input>
            </div>
          </div>

          <!-- Switch Is Publish -->
          <div class="block-input">
            <div class="col-title">
              <span class="subtitle">Is Publish</span>
            </div>
            <div class="width100">
              <n-space>
                <n-switch v-model:value="form.isPublish" />
              </n-space>
            </div>
          </div>
        </n-tab-pane>
        <n-tab-pane name="ru" tab="Rus">
          <m-input></m-input>
        </n-tab-pane>
        <n-tab-pane name="uk" tab="Ukr">
          <m-input></m-input>
        </n-tab-pane>
      </n-tabs>

      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updatePage">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "BlogEditPage",
  props: {
    id: String,
  },
  components: {
    QuillEditor,
  },
  data: () => {
    return {
      dataProperty: "",
      imageMain: "",
      imageLong: "",
      keyContent: "1",
      staticPageRes: "",
      options: "",
      form: {
        articleThemeId: "",
        content: "",
        createdAt: "",
        header: "",
        imageLongAlt: "",
        imageLongId: "",
        imageLongUrl: "",
        imageMainAlt: "",
        imageMainId: "",
        imageMainUrl: "",
        isPublish: true,
        metaDescription: "",
        metaKeywords: "",
        metaTitle: "",
        preview: "",
        slug: "",
        timeToRead: "",
      },
    };
  },
  mounted() {
    this.fetchArticle();
    this.fetchListCategorys();
  },
  watch: {
    form() {
      this.keyContent = Date.now();
    },
  },
  methods: {
    updatePage() {
      const url = `/articles/${this.id}`;
      const formData = new FormData();
      if (this.imageMain) {
        formData.append("files", this.imageMain, "imageMain");
      }
      if (this.imageLong) {
        formData.append("files", this.imageLong, "imageLong");
      }

      for (let el in this.form) {
        formData.append(el, this.form[el]);
      }

      this.$api
        .put(url, formData)
        .then(() => {
          this.alertShow("success", "Article update");
        })
        .catch(() => {
          this.alertShow("error", "Article not update");
        });
    },

    fetchArticle() {
      const url = `/articles/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.form = data;
        });
    },

    fetchListCategorys() {
      const url = "/article-themes";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.options = data.map((el) => {
            return {
              label: el.title,
              value: el.id,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteImage(key) {
      this.form[key] = "";
    },
  },
};
</script>
<style lang="scss">
.tab-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 30px;
}
</style>
