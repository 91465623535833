<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Currency</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/currency">Currency</router-link>/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <n-tabs type="line" animated size="large">
        <n-tab-pane name="main" tab="Main">
          <div class="tabs-content">
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Name</span>
              </div>
              <m-input
                placeholder="Name"
                :id="form.nameCurrency"
                v-model="form.nameCurrency"
              />
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Code</span>
              </div>
              <m-input
                @blur="v$.form.code.$touch"
                placeholder="Code"
                :id="form.code"
                v-model="form.code"
                textError="Invalid code currency"
                :isError="v$.form.code.$error"
              />
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Is Active</span>
              </div>
              <div class="width100">
                <n-space>
                  <n-switch v-model:value="form.isActive" />
                </n-space>
              </div>
            </div>
          </div>
        </n-tab-pane>
        <n-tab-pane name="logo" tab="Logo">
          <div class="tabs-content">
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Logo web</span>
              </div>
              <div class="width100">
                <img
                  v-if="form.imageWebUrl && form.imageWebUrl !== 'null'"
                  :src="form.imageWebUrl"
                  alt=""
                  width="600"
                  height="500"
                />
                <m-button
                  v-if="form.imageWebUrl && form.imageWebUrl !== 'null'"
                  style="margin-top: 20px"
                  @click="deleteImage('imageWebUrl')"
                  >Delete image</m-button
                >
                <m-upload
                  v-else
                  name="imageWeb"
                  v-model="imageWeb"
                  style="margin-top: 20px"
                >
                </m-upload>
              </div>
            </div>
            <div class="block-input">
              <div class="col-title">
                <span class="subtitle">Logo app</span>
              </div>
              <div class="width100">
                <img
                  v-if="form.imageAppUrl && form.imageAppUrl !== 'null'"
                  :src="form.imageAppUrl"
                  alt=""
                  width="600"
                  height="500"
                />
                <m-button
                  v-if="form.imageAppUrl && form.imageAppUrl !== 'null'"
                  style="margin-top: 20px"
                  @click="deleteImage('imageAppUrl')"
                  >Delete image</m-button
                >
                <m-upload
                  v-else
                  name="imageApp"
                  v-model="imageApp"
                  style="margin-top: 20px"
                >
                </m-upload>
              </div>
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updateCurrency()">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import cc from "currency-codes";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CurrencyEditPage",
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      imageWeb: "",
      imageApp: "",
      form: {
        nameCurrency: "",
        code: "",
        listActive: [],
        pair: "",
      },
    };
  },
  validations() {
    return {
      form: {
        code: {
          required,
          function(value) {
            return this.validCodeCurrency(value);
          },
        },
      },
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  mounted() {
    this.fetchCurrency(this.id);
  },
  methods: {
    validCodeCurrency(value) {
      if (cc.code(value)) {
        return true;
      } else {
        return false;
      }
    },
    async updateCurrency() {
      await this.v$.form.$touch();
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const url = `/currency/${this.id}`;
        const formData = new FormData();
        if (this.imageWeb) {
          formData.append("files", this.imageWeb, "imageWeb");
        }
        if (this.imageApp) {
          formData.append("files", this.imageApp, "imageApp");
        }

        for (let el in this.form) {
          formData.append(el, this.form[el]);
        }

        this.$api
          .put(url, formData)
          .then(() => {
            this.alertShow("success", "Currency create");
          })
          .catch(() => {
            this.alertShow("error", "Currency not create");
          });
      }
    },
    fetchCurrency(id) {
      const url = `/currency/${id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.form = data;
        });
    },
    deleteImage(key) {
      this.form[key] = "";
    },
  },
};
</script>
<style lang="scss">
.tab-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 30px;
}
</style>
