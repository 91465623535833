<template>
  <div class="form-search">
    <div class="grow">
      <label for="input">Withdrawals Id</label>
      <m-input
        placeholder="Withdrawals Id"
        id="input"
        type="number"
        @change="$emit('update:withdrawalId', $event.target.value)"
      ></m-input>
    </div>
    <div class="grow">
      <label for="Withdrawals">Status</label>
      <n-select
        id="Withdrawals"
        placeholder="Status"
        v-model:value="selectStatus"
        :options="withdrawalsStatus"
      />
    </div>
    <m-button
      style="margin-top: 22px"
      :imgUrl="require('@/assets/icons/search-button.svg')"
      @click="$emit('search', 1)"
    ></m-button>
  </div>
</template>
<script>
export default {
  name: "SearchWithdrawalsComponent",
  data: () => {
    return {
      selectStatus: null,
      selectReplenishment: null,
      replenishmentType: [
        {
          label: "send",
          value: "send",
        },
        {
          label: "receive",
          value: "receive",
        },
        {
          label: "",
          value: null,
        },
      ],
      withdrawalsStatus: [
        {
          label: "reject",
          value: "reject",
        },
        {
          label: "success",
          value: "success",
        },
        {
          label: "pending",
          value: "pending",
        },
        {
          label: "",
          value: null,
        },
      ],
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Enter text",
    },
    nameModel: {
      type: String,
      default: "value",
    },
  },
  watch: {
    selectStatus() {
      this.$emit("update:status", this.selectStatus);
    },
    selectReplenishment() {
      this.$emit("update:replenishment", this.selectReplenishment);
    },
  },
  methods: {},
};
</script>
<style lang="scss"></style>
