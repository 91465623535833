<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Roles</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/roles">Roles</router-link>/
        <router-link to="/" class="disabled-link">Create</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Name role</span>
        </div>
        <m-input placeholder="Name role" v-model="form.name" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Active</span>
        </div>
        <div class="width100">
          <n-space>
            <n-switch v-model:value="form.isActive" />
          </n-space>
        </div>
      </div>
      <div class="block-checkbox">
        <div class="permissions-title">
          <span class="subtitle">Permissions</span>
        </div>
        <div class="permissions-content">
          <button
            class="subtitle blue"
            style="margin-bottom: 40px"
            @click="allSelect"
          >
            Check/uncheck all
          </button>
          <div class="content-check__block">
            <n-checkbox-group v-model:value="form.permissions" size="large">
              <n-space item-style="display: flex;" vertical>
                <n-checkbox
                  :value="item.value"
                  :label="item.label"
                  v-for="(item, index) in listPermissions"
                  :key="index"
                />
              </n-space>
            </n-checkbox-group>
          </div>
        </div>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="createRole">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import { h } from "vue";
import { NButton } from "naive-ui";
export default {
  name: "RolesCreatePage",
  data: () => {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
          className: "textTop",
        },
        {
          title: "Role",
          key: "role",
          className: "textTop",
        },
        {
          title: "Permissions",
          key: "permissions",
          width: "400px",
        },
        {
          title: "Action",
          key: "actions",
          className: "textTop",
          render() {
            return [
              h(
                NButton,
                {
                  style: {
                    background: "#E2F54B",
                    padding: "4px",
                    width: "32px",
                    height: "32px",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  size: "large",
                  quaternary: true,
                  vertical: false,
                  onClick: () => alert("Button1"),
                },
                {
                  default: () => {
                    return h("img", {
                      src: require("@/assets/icons/button-edit.svg"),
                      alt: "edit",
                    });
                  },
                }
              ),
              h(
                NButton,
                {
                  style: {
                    background: "#FFFFFF",
                    padding: "4px",
                    width: "32px",
                    height: "32px",
                    border: "1px solid #CBD5E1",
                  },
                  size: "large",
                  vertical: false,
                  quaternary: true,
                  onClick: () => alert("Button2"),
                },
                {
                  default: () => {
                    return h("img", {
                      src: require("@/assets/icons/button-block.svg"),
                      alt: "block",
                    });
                  },
                }
              ),
              h(
                NButton,
                {
                  style: {
                    background: "#0F172A",
                    padding: "4px",
                    width: "32px",
                    height: "32px",
                    "border-radius": "0px 8px 8px 0px",
                    border: "none",
                  },
                  quaternary: true,
                  size: "large",
                  vertical: false,
                  onClick: () => alert("Button3"),
                },
                {
                  default: () => {
                    return h("img", {
                      src: require("@/assets/icons/button-delete.svg"),
                      alt: "delete",
                    });
                  },
                }
              ),
            ];
          },
        },
      ],
      listRoles: [
        {
          id: 1,
          role: "SuperAdmin",
          permissions: [
            "User browsing",
            "Creating users",
            " Editing users",
            "Removing users",
            "Block user",
            "User wallet: balance, status",
            "View user statistic",
            " User verification",
            "User deactivates",
            "Coin view",
            "Coin create",
            "Coin edit",
            "Coin delete",
            "Pair view",
            "Pair edit",
            "Pair create",
            "Pair delete",
            "Exchange deals",
            "Withdraw view",
            "Withdraw edit",
            "Deposits view",
            "Deposits status management",
            "Balances view",
            "Transaction live",
            " Transaction history",
            "Fee management",
            "Bot settings",
            " Static page browsing",
            " Creating static pages",
            "Editing static pages",
            " Removing static pages",
            "Text block management",
            "Phrase management",
            "News management",
            "Announcements management",
            "FAQ management",
            "Community management",
            "Advantages management",
            "Languages management",
            "Slider management",
            "Support management",
            "Listing management",
            "Subscribers management",
            "Phone codes management",
            " Trading",
            "User bank requisites",
            "Chat",
            " Income",
            "Bonuses management",
            "Service statistic",
            "Params",
            "Approve business account requests",
            "User logs",
          ],
        },
        {
          id: 2,
          role: "Admin",
          permissions: [
            "Static page browsing",
            "Creating static pages",
            "Editing static pages",
            "Removing static pages",
          ],
        },
        {
          id: 3,
          role: "Content manager",
          permissions: [
            "Bot settings",
            "News management",
            "Bonuses management",
            "User wallet: balance, status",
            "Coin create",
            "Pair create",
            " Withdraw edit",
            "Deposits status management",
            "Fee management",
            "Slider management",
            "Pair edit",
            "Withdraw view",
            "User browsing",
            "Deposits view",
            "Coin view",
            "Pair view",
            "Editing users",
            "Has access to admin area",
          ],
        },
      ],
      listPermissions: [],
      form: {
        name: "",
        permissions: [],
        isActive: true,
      },
    };
  },
  mounted() {
    this.fetchPermisions();
  },
  methods: {
    createRole() {
      const url = "/roles";
      this.$api
        .post(url, this.form)
        .then((res) => res.data)
        .then(() => this.alertShow("success", "Role create"))
        .catch((err) => {
          console.log(err);
          this.alertShow("error", "Role not create");
        });
    },
    removeSelectedFilter(index) {
      delete this.form.permissions[index];
    },
    isChecked(index) {
      return !!this.form.permissions[index];
    },
    allSelect() {
      this.form.permissions = this.form.permissions.length
        ? []
        : this.listPermissions.map((el) => el.value);
    },
    fetchPermisions() {
      const url = "/roles/permissions";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listPermissions;
          for (const el in data) {
            this.listPermissions.push({ label: el, value: data[el] });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.textTop {
  vertical-align: top;
}
.block-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-title {
  width: 200px;
}
.block-checkbox {
  display: flex;
  margin-bottom: 20px;
  .permissions-title {
    padding-right: 95px;
  }
}
.ul-style {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}
.content-block__button {
  display: flex;
  justify-content: end;
  gap: 10px;
  .btn_cancel {
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 32px;
    &:hover {
      background: #eeeeee;
    }
  }
}
</style>
