<template lang="html">
  <div class="container">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
    <div class="header">
      <div class="title">Categories</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Categories</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="create"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute)"
          :data="list"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
    <m-modal
        :open="showModalDelete"
        :title="modelTitle"
        :subtitle="modelSubtitle"
        :nameButtonActive="nameButtonActive"
        @cancel-event="closeModel"
        @active-event="deleteCategory(deleteId)"
    ></m-modal>
  </div>
</template>
<script>
import { h } from "vue";
import {NButton, NTag} from "naive-ui";
export default {
  name: "FAQCategoriesPage",
  data: () => {
    return {
      createColumns: (openModal, goToRoute) => {
        return [
          {
            title: "Id",
            key: "id",
          },
          {
            title: "Name",
            key: "title",
          },
          {
            title: "Is Active",
            key: "isPublish",
            render(row) {
              const status = row.isPublish;
              let colorBG = "";
              let colorText = "";
              if (status) {
                colorBG = "#EDF6F2";
                colorText = "#4DA97D";
              } else {
                colorBG = "#FEEFF2";
                colorText = "#C04949";
              }
              return h(
                  NTag,
                  {
                    style: {
                      padding: "16px",
                      color: colorText,
                      background: colorBG,
                      fontSize: "14px",
                    },
                    round: true,
                    bordered: false,
                  },
                  {
                    default: () => {
                      return `${status}`;
                    },
                  }
              );
            },
          },
          {
            title: 'Action',
            key: 'actions',
            render(row) {
              return [
                h(
                    NButton,
                    {
                      style: {
                        background: '#E2F54B',
                        padding: '4px',
                        width: '32px',
                        height: '32px',
                        'border-radius': '8px 0px 0px 8px'
                      },
                      size: 'large',
                      quaternary: true,
                      vertical: false,
                      onClick: () => goToRoute('faq-categories-edit', row.id)
                    },
                    {
                      default: () => {
                        return h('img', {
                          src: require('@/assets/icons/button-edit.svg'),
                          alt: 'edit'
                        })
                      }
                    }
                ),
                h(
                    NButton,
                    {
                      style: {
                        background: '#0F172A',
                        padding: '4px',
                        width: '32px',
                        height: '32px',
                        'border-radius': '0px 8px 8px 0px',
                        border: 'none'
                      },
                      quaternary: true,
                      size: 'large',
                      vertical: false,
                      onClick: () => openModal('delete', row.id)
                    },
                    {
                      default: () => {
                        return h('img', {
                          src: require('@/assets/icons/button-delete.svg'),
                          alt: 'delete'
                        })
                      }
                    }
                )
              ]
            }
          }
        ]
      },
      list: [],
    };
  },
  mounted() {
    this.fetchPaymentMethod()
  },
  methods: {
    create() {
      this.$router.push({ path: "categories/create" });
    },
    deleteCategory(id) {
      this.$api
          .delete('/faq-category/' + id)
          .then(() => {
            this.alertShow("success", "Category deleted");
            this.closeModel();
            this.fetchPaymentMethod()
          })
          .catch((err) => {
            this.closeModel();
            console.log(err.response)
            this.alertShow("error", err.response.data.message);
          });
    },
    fetchPaymentMethod() {
      console.log('hello')
      this.$api
          .get('/faq-category')
          .then((data) => {
            this.list = data.data
            console.log(data)
          });
    },
  },
};
</script>
<style lang=""></style>
