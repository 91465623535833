<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Users</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/users">Users</router-link>/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Id</span>
        </div>
        <div class="width100">{{ user.id }}</div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">firstName</span>
        </div>
        <m-input :placeholder="form.firstName" v-model="form.firstName" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">lastName</span>
        </div>
        <m-input :placeholder="form.lastName" v-model="form.lastName" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">countryName</span>
        </div>
        <m-input :placeholder="form.countryName" v-model="form.countryName" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Username</span>
        </div>
        <m-input :placeholder="form.name" v-model="form.name" />
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Email</span>
        </div>
        <m-input :placeholder="form.email" v-model="form.email" />
      </div>
      <div class="block-input">
        <div class="col-title"></div>
        <div class="block_switch">
          <span class="mar-right20">isEmailConfirmed</span>
          <n-space>
            <n-switch v-model:value="form.isEmailConfirmed" />
          </n-space>
        </div>
      </div>
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Phone</span>
        </div>
        <m-input :placeholder="form.phone" v-model="form.phone" />
      </div>
      <div class="block-input">
        <div class="col-title"></div>
        <div class="block_switch">
          <span class="mar-right20">isPhoneNumberConfirmed</span>
          <n-space>
            <n-switch v-model:value="form.isPhoneNumberConfirmed" />
          </n-space>
        </div>
      </div>
      <div class="block-checkbox">
        <div class="col-title">
          <span class="subtitle">Role</span>
        </div>
        {{ form.roleId }}
        <n-checkbox-group v-model:value="roles" size="large">
          <n-space item-style="display: flex;" vertical>
            <n-checkbox
              :value="item.value"
              :label="item.label"
              v-for="(item, index) in valueRoles"
              :key="index"
            />
          </n-space>
        </n-checkbox-group>
      </div>
      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="save">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UsersEditPage",
  data: () => {
    return {
      valueRoles: [],
      roles: [],
      form: {
        countryName: "",
        email: "",
        firstName: "",
        isEmailConfirmed: "",
        isPhoneNumberConfirmed: "",
        lastName: "",
        name: "",
        phone: "",
        roles: [],
      },
      user: {},
    };
  },
  watch: {
    user() {
      for (let el in this.form) {
        this.form[el] = this.user[el];
      }
    },
  },
  props: {
    id: {
      type: String,
    },
  },
  mounted() {
    this.fetchUserById();
    this.fetchRoles();
  },
  methods: {
    fetchUserById() {
      const url = `/users/user/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.form = data;
          this.roles = data.roles.map((el) => el.id);
          this.user = data;
        });
    },
    save() {
      const url = `/users/user/${this.id}`;
      this.form.roles = this.roles.map((el) => {
        return this.listRoles.find((data) => data.id === el);
      });
      this.$api
        .patch(url, this.form)
        .then(() => this.alertShow("success", "User update"))
        .catch(() => {
          this.alertShow("error", "User not update");
        });
    },
    fetchRoles() {
      const url = "/roles";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listRoles = data;
          this.valueRoles = data.map((el) => ({
            value: el.id,
            label: el.name,
          }));
        });
    },
  },
};
</script>
<style lang="scss">
.block_switch {
  display: flex;
  width: 100%;
  .mar-right20 {
    margin-right: 20px;
  }
}
</style>
