<template>
  <div class="container" :class="{ 'active-modal': showModal }">
    <div class="header">
      <div class="title">Deposit</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Deposit</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute)"
          :data="listContact"
          :single-line="false"
        />
        <n-pagination
            class="top20"
            :item-count="total"
            size="large"
            :default-page-size="paramsFilter.limit"
            @update:page="updatePage"
        >
        </n-pagination>
      </div>
    </div>
    <m-modal
      :open="showModal"
      title="Delete"
      subtitle="Are you sure you want to delete request?"
      @cancel-event="closeModel"
      @active-event="deleteRequest"
    ></m-modal>
  </div>
</template>
<script>
import { h } from "vue";
import { NTag } from "naive-ui";
export default {
  name: "DepositPage",
  data: () => {
    return {
      showModal: false,
      contactId: "",
      total: null,
      paramsFilter: {
        page: 1,
        limit: 10,
      },
      createColumns: () => {
        return [
          {
            title: "ID",
            key: "id",
          },
          {
            title: "User",
            key: "toUser.name",
          },
          {
            title: "Currency",
            key: "receivedCoin",
          },
          {
            title: "Amount",
            key: "receivedAmount",
          },
          {
            title: "Wallet address",
            key: "toAddress",
          },
          {
            title: "Status",
            key: "status",
            render(row) {
              const status = row.status;
              let colorBG = "";
              let colorText = "";
              if (status === "Confirmed") {
                colorBG = "#EDF6F2";
                colorText = "#4DA97D";
              } else if (status === "Canceled") {
                colorBG = "#FEEFF2";
                colorText = "#C04949";
              } else {
                colorBG = "#F1F5F9";
                colorText = "#64748B";
              }
              return h(
                NTag,
                {
                  style: {
                    padding: "16px",
                    color: colorText,
                    background: colorBG,
                    fontSize: "14px",
                  },
                  round: true,
                  bordered: false,
                },
                {
                  default: () => {
                    return `${row.status}`;
                  },
                }
              );
            },
          },
          {
            title: "Data create ",
            key: "createdAt",
            render(row) {
              const date = new Date(row.createdAt);
              const year = date.getFullYear();
              const day = date.getDate();
              const correctDay = day < 10 ? `0${day}` : day;
              const mount = date.getMonth();
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const seconds = date.getSeconds();
              const correctMount = mount < 10 ? `0${mount}` : mount;
              return `${correctDay}.${correctMount}.${year} ${hours}:${minutes}:${seconds}`;
            },
          },
          {
            title: "Network",
            key: "network",
          },
        ];
      },
      listContact: [],
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    updatePage(page) {
      this.paramsFilter.page = page
      this.fetchData()
    },
    fetchData() {
      this.$api.get('/transaction?type=deposit&limit=10&page=' + this.paramsFilter.page).then(res => {
        console.log(res)
        this.listContact = res.data.transactions
        this.total = res.data.count;
      })
    },
    openModal(row) {
      this.contactId = row.id;
      this.showModal = true;
    },
    closeModel() {
      this.contactId = "";
      this.showModal = false;
    },
    deleteRequest() {
      console.log("deleteRequest", this.contactId);
    },
    goToRoute(row) {
      console.log(row.id);
      this.$router.push({ name: "deposit-edit", params: { id: row.id } });
    },
  },
};
</script>
<style lang=""></style>
