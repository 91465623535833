<template>
  <div class="container">
    <div class="header">
      <div class="title">Transaction</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Transaction</router-link>
      </div>
    </div>
    <div class="block-content">
      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="columns"
          :data="list"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { h } from "vue";
import { NTag } from "naive-ui";
export default {
  name: "TransactionPage",
  data: () => {
    return {
      columns: [
        {
          title: "ID",
          key: "id",
        },
        {
          title: "Pair",
          key: "pair",
        },
        {
          title: "Side",
          key: "side",
          render(row) {
            const status = row.side;
            let colorBG = "";
            let colorText = "";
            if (status === "Buy") {
              colorBG = "#EDF6F2";
              colorText = "#4DA97D";
            } else if (status === "Sell") {
              colorBG = "#FEEFF2";
              colorText = "#C04949";
            } else {
              colorBG = "#F1F5F9";
              colorText = "#64748B";
            }
            return h(
              NTag,
              {
                style: {
                  padding: "16px",
                  color: colorText,
                  background: colorBG,
                  fontSize: "14px",
                },
                round: true,
                bordered: false,
              },
              {
                default: () => {
                  return `${row.side}`;
                },
              }
            );
          },
        },
        {
          title: "Price",
          key: "price",
        },
        {
          title: "Quantity",
          key: "quantity",
        },
        {
          title: "Fee",
          key: "fee",
        },
        {
          title: "Status",
          key: "status",
          render(row) {
            const status = row.status;
            let colorBG = "";
            let colorText = "";
            if (status === "Completed") {
              colorBG = "#EDF6F2";
              colorText = "#4DA97D";
            } else if (status === "Canceled") {
              colorBG = "#FEEFF2";
              colorText = "#C04949";
            } else {
              colorBG = "#F1F5F9";
              colorText = "#64748B";
            }
            return h(
              NTag,
              {
                style: {
                  padding: "16px",
                  color: colorText,
                  background: colorBG,
                  fontSize: "14px",
                },
                round: true,
                bordered: false,
              },
              {
                default: () => {
                  return `${row.status}`;
                },
              }
            );
          },
        },
        {
          title: "Created",
          key: "createdAt",
          render(row) {
            const date = new Date(row.createdAt);
            const year = date.getFullYear();
            const day = date.getDate();
            const correctDay = day < 10 ? `0${day}` : day;
            const mount = date.getMonth();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const correctMount = mount < 10 ? `0${mount}` : mount;
            return `${correctDay}.${correctMount}.${year} ${hours}:${minutes}:${seconds}`;
          },
        },
        {
          title: "Completed",
          key: "completedAt",
          render(row) {
            const date = new Date(row.completedAt);
            const year = date.getFullYear();
            const day = date.getDate();
            const correctDay = day < 10 ? `0${day}` : day;
            const mount = date.getMonth();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const correctMount = mount < 10 ? `0${mount}` : mount;
            return `${correctDay}.${correctMount}.${year} ${hours}:${minutes}:${seconds}`;
          },
        },
      ],
      list: [],
    };
  },
};
</script>
<style lang=""></style>
