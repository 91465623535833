<template>
  <div class="container">
    <div class="header">
      <div class="title">Currency</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/deals">Deals</router-link>/
        <router-link to="/" class="disabled-link">Deal {{ id }}</router-link>
      </div>
    </div>
    <div class="block-info">
      <div class="title">Информация о сделке</div>
      <div class="subtitle">{{ Math.abs(deal.quantity) }} {{ order.coin }} зарезервировано для этой сделки.</div>
      <div class="subtitle">
        Покупатель должен заплатить
        {{ ((deal.price / deal.quantity).toFixed(2) * Math.abs(deal.quantity)).toFixed(2) }}
        {{ order.currency?.code }}
      </div>
      <div class="block-more-info">
        <div class="info-island">
          <div class="name-island">КУРС</div>
          <div class="description-island">{{ (deal.price / deal.quantity).toFixed(2) }} {{ order.currency?.code }}</div>
        </div>
        <div class="info-island">
          <div class="name-island">ID СДЕЛКИ</div>
          <div class="description-island">{{ id }}</div>
        </div>
        <div class="info-island">
          <div class="name-island">Открыта</div>
          <div class="description-island">
            {{ getTimeStartDeal(deal.createdAt) }}
          </div>
        </div>
        <div class="info-island">
          <div class="name-island">Оплата покупателя</div>
          <div class="description-island">
            {{ ((deal.price / deal.quantity).toFixed(2) * Math.abs(deal.quantity)).toFixed(2) }}
            {{ order.currency?.code }}
          </div>
        </div>
      </div>
    </div>
    <div class="block-content">
      <div class="block-chat">
        <div class="block-cards">
          <div class="card-chat" ref="buyer" @click="chatBuyer(buyer.name, buyer.id)">
            <div class="who-card">Покупатель</div>
            <div class="user-info-block">
              <div class="avatar">
                <img :src="buyer.avatarUrl" alt="byer" height="50" width="50" />
              </div>
              <div class="info">
                <div class="user-name">{{ buyer.name }}</div>
                <div class="network">
                  <span class="online" v-if="userOnline(buyer.lastActivity)">В сети</span>
                  <span class="ofline" v-else>Не в сети</span>
                </div>
                <div class="user-rate">
                  <n-rate :value="round(buyer.rating, 0.5)" allow-half="true" readonly="true" /> {{ buyer.rating }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-chat" ref="seller" @click="chatSeller(seller.name, seller.id)">
            <div class="who-card">Продавец</div>
            <div class="user-info-block">
              <div class="avatar">
                <img :src="seller.avatarUrl" alt="seller" height="50" width="50" />
              </div>
              <div class="info">
                <div class="user-name">{{ seller.name }}</div>
                <div class="network">
                  <span class="online" v-if="userOnline(seller.lastActivity)">В сети</span>
                  <span class="ofline" v-else>Не в сети</span>
                </div>
                <div class="user-rate">
                  <n-rate :value="round(seller.rating, 0.5)" allow-half="true" readonly="true" /> {{ seller.rating }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat" v-show="chatShow">
          <div class="status-manager">
            <span class="online" v-if="own.online">В сети</span>
            <span class="ofline" v-else>Не в сети</span>
          </div>
          <div class="with-chat">Сейчас вы в чате с {{ opponent.role }}</div>
          <div class="chat-body" ref="chat">
            <div class="chat-message" v-for="el in chat" :key="el.createdAt">
              <div v-if="el.type === dealsTypeEnum.userMessage || el.type === dealsTypeEnum.uploadFile">
                <div class="oponent-message" v-if="opponent.id === el.fromUserId">
                  <div class="oponent-message-block">
                    <span class="chat-time" style="margin: 0 0 10px"> Author: {{ opponent.name }} </span>
                    <div style="margin-bottom: 20px">
                      <div v-if="isTypePdf(el.link)">
                        <object type="application/x-pdf" title="SamplePdf" width="300" height="300">
                          <embed :src="el.link" :alt="el.link" v-if="el.link" style="width: 100%; height: 100%" />
                          <a :href="el.link" target="_blank">Open</a>
                        </object>
                      </div>
                      <div v-else>
                        <img v-if="el.link" id="image" ref="image" :src="el.link" width="300" height="300" />
                        <a v-if="el.link" :href="el.link" target="_blank">Open</a>
                      </div>
                    </div>
                    <span v-html="el.message"></span>
                    <span class="chat-time">
                      {{ getCorrectTime(el.createdAt) }}
                    </span>
                  </div>
                </div>
                <div class="our-message" v-if="otherOpponent.id === el.fromUserId && el.toUserId === null">
                  <div class="our-message-block">
                    <span class="chat-time" style="margin: 0 0 10px"> Author: {{ otherOpponent.name }} </span>
                    <div style="margin-bottom: 20px">
                      <div v-if="isTypePdf(el.link)">
                        <object type="application/x-pdf" title="SamplePdf" width="300" height="300">
                          <embed :src="el.link" :alt="el.link" v-if="el.link" style="width: 100%; height: 100%" />
                          <a :href="el.link" target="_blank">Open</a>
                        </object>
                      </div>
                      <div v-else>
                        <img v-if="el.link" id="image" ref="image" :src="el.link" width="300" height="300" />
                        <a v-if="el.link" :href="el.link" target="_blank">Open</a>
                      </div>
                    </div>
                    <span v-html="el.message"></span>
                    <span class="chat-time">
                      {{ getCorrectTime(el.createdAt) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="system-message" v-if="el.type === dealsTypeEnum.dealOpen">
                <span> Сделка открыта </span>
                <span class="chat-time">
                  {{ getCorrectTime(el.createdAt) }}
                </span>
              </div>
              <div class="system-message" v-if="el.type === dealsTypeEnum.moderatorConnect">
                <span> Модератор присоединился к сделке </span>
                <span class="chat-time">
                  {{ getCorrectTime(el.createdAt) }}
                </span>
              </div>
              <div class="system-message" v-if="el.type === dealsTypeEnum.dealCompleted">
                <span> {{ el.message }} </span>
                <span class="chat-time">
                  {{ getCorrectTime(el.createdAt) }}
                </span>
              </div>
              <div class="system-message" v-if="el.type === dealsTypeEnum.dealCanceled">
                <span> {{ el.message }} </span>
                <span class="chat-time">
                  {{ getCorrectTime(el.createdAt) }}
                </span>
              </div>
              <div
                class="our-message"
                v-if="
                  (el.type === dealsTypeEnum.adminMessage || el.type === dealsTypeEnum.adminUploadFile) &&
                  opponent.id === el.toUserId
                "
              >
                <div class="our-message-block">
                  <span class="chat-time" style="margin: 0 0 10px"> Author: {{ admin.name }} </span>
                  <div style="margin-bottom: 20px">
                    <div v-if="isTypePdf(el.link)">
                      <object type="application/x-pdf" title="SamplePdf" width="300" height="300">
                        <embed :src="el.link" :alt="el.link" v-if="el.link" style="width: 100%; height: 100%" />
                        <a :href="el.link" target="_blank">Open</a>
                      </object>
                    </div>
                    <div v-else>
                      <img v-if="el.link" width="300" height="300" id="image" ref="image" :src="el.link" />
                      <a v-if="el.link" :href="el.link" target="_blank">Open</a>
                    </div>
                  </div>
                  <span v-html="el.message"></span>
                  <span class="chat-time">
                    {{ getCorrectTime(el.createdAt) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="description-disput" v-if="whoDisputOpen.name">
            {{ whoDisputOpen.name }} открыл спор. Причина: {{ whoDisputOpen.message }}
          </div>
          <div
            class="input-message"
            v-if="deal.status === dealsStatusEnum.disputeOpen && deal.responsibleId === admin.id"
          >
            <div class="block-message">
              <div id="preview">
                <object type="application/x-pdf" title="SamplePdf" width="100" height="300" id="image">
                  <div v-if="isTypeImage(file)">
                    <img v-if="url" id="image" ref="image" :src="url" />
                  </div>
                  <div v-else>
                    <embed :src="url" :alt="url" v-if="url" style="width: 100%; height: 100%" />
                  </div>
                </object>
                <label v-if="url" class="remove-image-btn" for="image" v-on:click="removeFile()">
                  <img src="@/assets/icons/modal_close.svg" />
                </label>
              </div>
              <!-- <input v-model="text" type="text" placeholder="" /> -->
              <textarea v-model="text" placeholder="Напишите сообщение..."></textarea>
            </div>
            <div class="image-upload">
              <label for="file-input">
                <img src="@/assets/icons/chat-upload-icon.svg" />
              </label>

              <input id="file-input" type="file" @change="onFileChange" />
            </div>
            <div>
              <m-button
                :imgUrl="require('@/assets/icons/send-message-icon.svg')"
                heightButton="42px"
                @click="sendMessage()"
                >Send</m-button
              >
            </div>
          </div>
          <div
            class="block-button"
            v-if="deal.status === dealsStatusEnum.disputeOpen && deal.responsibleId === admin.id"
          >
            <m-button widthButton="49%" @click="openModalDeal('send')">Отправить деньги и закрыть</m-button>
            <m-button widthButton="49%" @click="openModalDeal('return')">Вернуть деньги и закрыть</m-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="openModalSendMoney" class="modal">
    <div class="modal_header">
      <span class="modal_title">Решение спора</span>
      <button type="button" class="btn-close" @click="closeModal">
        <img src="@/assets/icons/modal_close.svg" alt="X" />
      </button>
    </div>
    <div class="modal_subtitle">Вы хотите отправить деньги покупателю?</div>
    <div class="modal_button">
      <m-button widthButton="98px" class="cancel" @click="closeModal">Отмена</m-button>
      <m-button widthButton="98px" @click="sendMoney()">Отправить</m-button>
    </div>
  </div>
  <div v-if="openModalReturnMoney" class="modal">
    <div class="modal_header">
      <span class="modal_title">Решение спора</span>
      <button type="button" class="btn-close" @click="closeModal">
        <img src="@/assets/icons/modal_close.svg" alt="X" />
      </button>
    </div>
    <div class="modal_subtitle">Вы хотите вернуть деньги продавцу?</div>
    <div class="modal_button">
      <m-button widthButton="98px" class="cancel" @click="closeModal">Отмена</m-button>
      <m-button widthButton="98px" @click="returnMoney()">Вернуть</m-button>
    </div>
  </div>
</template>
<script>
import dateFormat from 'dateformat'
import { mapGetters } from 'vuex'
import { dealsTypeEnum } from '../enum/dealsTypeEnum'
import { dealsStatusEnum } from '../enum/dealsStatusEnum'
export default {
  name: 'DealsInfoPage',
  props: {
    id: {
      type: String
    }
  },
  data: () => {
    return {
      url: '',
      file: null,
      text: '',
      chatShow: false,
      dealsTypeEnum: dealsTypeEnum,
      dealsStatusEnum: dealsStatusEnum,
      openModalSendMoney: false,
      openModalReturnMoney: false,
      own: {
        online: true
      },
      opponent: {
        name: '',
        role: '',
        id: null
      },
      whoDisputOpen: {},
      otherOpponent: {
        name: '',
        role: '',
        id: null
      },
      deal: {},
      order: {},
      buyer: {},
      seller: {},
      disputText: 'Спор открыт пользователем ',
      chat: null
    }
  },
  mounted() {
    this.fetchDeal()
  },
  computed: {
    ...mapGetters({ admin: 'AuthStore/getAdmin' }),
    ...mapGetters({ event: 'SocketEventStore/getEvent' })
  },
  watch: {
    event() {
      if (this.event?.data?.dealId === Number(this.id)) {
        this.fetchDeal()
      }
    }
  },
  updated() {
    var elem = this.$refs.chat
    elem.scrollTop = elem.scrollHeight
    this.whoDisputOpenMethod()
  },
  methods: {
    fetchDeal() {
      const url = `/deals/${this.id}`
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.seller = { ...data.seller }
          this.buyer = { ...data.buyer }
          this.chat = data.events
          this.order = data.order
          this.deal = data
        })
    },
    getCorrectTime(date) {
      return dateFormat(date, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      this.url = URL.createObjectURL(this.file)
    },
    removeFile() {
      this.file = null
      this.url = ''
    },
    chatBuyer(name, id) {
      this.opponent.name = name
      this.opponent.role = 'покупатель'
      this.opponent.id = id
      this.otherOpponent.name = this.seller.name
      this.otherOpponent.id = this.seller.id
      this.chatShow = true
      this.$refs.buyer.classList.add('active-chat')
      this.$refs.seller.classList.remove('active-chat')
    },
    chatSeller(name, id) {
      this.opponent.name = name
      this.opponent.role = 'продавец'
      this.opponent.id = id
      this.otherOpponent.name = this.buyer.name
      this.otherOpponent.id = this.buyer.id
      this.chatShow = true
      this.$refs.seller.classList.add('active-chat')
      this.$refs.buyer.classList.remove('active-chat')
    },
    async sendMessage() {
      if (this.text?.trim() || this.file) {
        let message = {
          dealId: this.id,
          message: this.text.replace(/\n/g, '<br/>'),
          toUserId: this.opponent.id
        }
        const url = `/messages`
        const formData = new FormData()
        if (this.file) {
          formData.append('file', this.file)
        }
        for (let el in message) {
          formData.append(el, message[el])
        }
        await this.$api
          .post(url, formData)
          .then((res) => res.data)
          .then((data) => {
            this.chat = data.events
            this.removeFile()
          })
          .catch((err) => {
            console.log(err)
          })
        this.text = ''
      }
    },
    clearMessage() {
      this.messageObj = {
        link: null,
        text: '',
        author: null,
        createdAt: null
      }
    },
    getTimeStartDeal(date) {
      const nowTime = Date.now()
      const dateCreate = new Date(date).getTime()
      const residueTime = nowTime - dateCreate
      let seconds = Math.floor(residueTime / 1000)
      let minutes = Math.floor((seconds % 3600) / 60)
      let hours = Math.floor((seconds % (3600 * 24)) / 3600)
      let days = Math.floor(seconds / (3600 * 24))

      let display = days > 0 ? `${days} дня ` : ``
      display = hours > 0 ? display + `${hours} часов ` : display + ``
      display = minutes > 0 ? display + `${minutes} минуты назад` : display + `назад`
      return display
    },
    isTypeImage(file) {
      if (file?.type.split('/')[0] === 'image') {
        return true
      } else {
        return false
      }
    },
    isTypePdf(url) {
      return url?.split('.').pop() === 'pdf'
    },
    whoDisputOpenMethod() {
      if (this.deal.events) {
        const event = Object.values(this.deal.events).find((el) => el.type === this.dealsTypeEnum.disputeOpen)
        if (event) {
          this.whoDisputOpen.name = this.buyer.id === event.fromUserId ? 'Покупатель' : 'Продавец'
          this.whoDisputOpen.message = event.message
        }
      }
    },
    sendMoney() {
      const url = `/deals/send-money`
      this.$api
        .post(url, { dealId: this.id })
        .then((res) => {
          res.data
        })
        .then(() => {
          this.fetchDeal()
        })
      this.closeModal()
    },
    returnMoney() {
      const url = `/deals/return-money`
      this.$api
        .post(url, { dealId: this.id })
        .then((res) => {
          res.data
        })
        .then(() => {
          this.fetchDeal()
        })
      this.closeModal()
    },
    openModalDeal(type) {
      if (type === 'return') {
        this.openModalReturnMoney = true
      }
      if (type === 'send') {
        this.openModalSendMoney = true
      }
    },
    closeModal() {
      this.openModalSendMoney = false
      this.openModalReturnMoney = false
    },
    round(value, step) {
      step || (step = 1.0)
      const inv = 1.0 / step
      return Math.round(value * inv) / inv
    },
    userOnline(date) {
      return new Date(date).getTime() + 5 * 60 * 1000 > Date.now()
    },
    textRead(text) {
      console.log(text)
      return 'text'
    }
  }
}
</script>
<style lang="scss">
.system-message {
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin: 20px 0;
  width: 100%;
  background: #fbefd2;
  border-radius: 12px 12px 12px 0px;
}
.online {
  position: relative;
  padding-left: 15px;
  &:before {
    content: '';
    position: absolute;
    background: green;
    top: 6px;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 20px;
  }
}
.ofline {
  position: relative;
  padding-left: 15px;
  &:before {
    content: '';
    position: absolute;
    background: red;
    top: 6px;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 20px;
  }
}
.block-more-info {
  display: flex;
  gap: 20px;
  margin: 32px 0;
  .info-island {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 214px;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 101px rgba(88, 91, 66, 0.14);
    border-radius: 12px;

    .name-island {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #64748b;
    }
    .description-island {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #0f172a;
    }
  }
}
.block-chat {
  .block-cards {
    .card-chat {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 20px;
      background: #a8adb2;
      height: 152px;
      border-radius: 12px;
      flex-grow: 1;

      .user-info-block {
        display: flex;
        gap: 20px;

        .info {
          .user-rate {
            display: flex;
            gap: 10px;
            align-items: center;
            height: 20px;
          }
        }
      }
    }
    .active-chat {
      background: #0f172a;
      color: #ffffff;
    }
  }
}
.chat {
  .status-manager {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    gap: 4px;

    width: 100%;
    height: 44px;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #e2e8f0;
  }
  .with-chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    gap: 4px;

    width: 100%;
    height: 44px;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #e2e8f0;
  }
  .chat-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    max-height: 400px;
    background: #ffffff;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #e2e8f0;
    overflow: auto;
    scroll-snap-align: end;
    .chat-message {
      width: 100%;
      .oponent-message {
        display: flex;
        justify-content: flex-start;
        &-block {
          display: flex;
          flex-direction: column;
          max-width: 80%;
          padding: 10px;
          margin: 10px;
          background: #e2e8f0;
          border-radius: 0 12px 12px 12px;
        }
      }
      .our-message {
        display: flex;
        justify-content: flex-end;
        &-block {
          display: flex;
          flex-direction: column;
          max-width: 80%;
          padding: 10px;
          margin: 10px;
          background: #e2e8f0;
          border-radius: 12px 12px 0 12px;
        }
      }
      .chat-time {
        margin-top: 20px;
        font-size: 12px;
        color: #96a0ac;
      }
    }
  }
  .description-disput {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    margin: 20px 0;

    width: 100%;

    background: #fbefd2;
    border-radius: 12px 12px 12px 0px;
  }
  .input-message {
    display: flex;
    align-items: end;
    padding: 10px;
    gap: 9px;
    width: 100%;
    height: auto;
    border: 1px solid #cbd5e1;
    border-radius: 12px;
    #preview {
      position: relative;
      max-width: 100px;
      img {
        max-width: 100px;
      }
      .remove-image-btn {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: -10px;
        height: 5px;
        width: 5px;
      }
    }
    .block-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      input {
        border: none;
        &:hover,
        &:active,
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
      textarea {
        border: none;
        resize: none;
        &:hover,
        &:active,
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
    .image-upload > input {
      display: none;
    }

    .image-upload img {
      cursor: pointer;
      padding: 10px 12px;
      width: 66px;
      height: 42px;
      border: 1px solid #cbd5e1;
      border-radius: 32px;
    }
  }
  .block-button {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}
.avatar {
  img {
    border-radius: 50px;
  }
}
</style>
