export const modal = {
  data: () => {
    return {
      showModal: false,
      nameButtonActive: "",
      modelTitle: "",
      modelSubtitle: "",
      deleteId: "",
      showModalBlock: false,
      showModalDelete: false,
    };
  },
  methods: {
    openModal(type, id) {
      if (type === "block") {
        this.modelTitle = "Block";
        this.modelSubtitle = `Are you sure you want to blocked id:${id}?`;
        this.nameButtonActive = "Block";
        this.showModalBlock = true;
        this.deleteId = id;
      } else if (type === "delete") {
        this.modelTitle = "Delete";
        this.modelSubtitle = `Are you sure you want to delete id:${id}?`;
        this.nameButtonActive = "Delete";
        this.showModalDelete = true;
        this.deleteId = id;
      }
      this.showModal = true;
    },
    closeModel() {
      this.contactId = "";
      this.showModal = false;
      this.showModalDelete = false;
      this.showModalBlock = false;
    },
    goToRoute(name, id) {
      this.$router.push({ name, params: { id: id } });
    },
  },
};
