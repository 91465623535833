<template>
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Static pages</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link class="route" to="/content/static-pages"
          >Static pages</router-link
        >/
        <router-link to="/" class="disabled-link">Edit</router-link>
      </div>
    </div>
    <div class="block-content">
      <!-- Header -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Header</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <m-input :value="form.header" v-model="form.header"></m-input>
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <m-input></m-input>
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <m-input></m-input>
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <!-- Slug -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Slug</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <m-input :value="form.slug" v-model="form.slug"></m-input>
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <m-input></m-input>
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <m-input></m-input>
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <!-- Content -->
      <div class="block-input">
        <div class="col-title">
          <span class="subtitle">Content</span>
        </div>
        <div class="width100">
          <n-tabs type="card">
            <n-tab-pane name="en" tab="Eng">
              <QuillEditor
                :content="form.content"
                contentType="html"
                toolbar="minimal"
                theme="snow"
                v-model:content="form.content"
                :key="keyContent"
              />
            </n-tab-pane>
            <n-tab-pane name="ru" tab="Rus">
              <QuillEditor theme="snow" v-model:content="dataProperty" />
            </n-tab-pane>
            <n-tab-pane name="uk" tab="Ukr">
              <QuillEditor theme="snow" v-model:content="dataProperty" />
            </n-tab-pane>
          </n-tabs>
        </div>
      </div>
      <!-- Photo -->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Photo</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <img-->
<!--            v-if="form.webViewLink"-->
<!--            :src="form.webViewLink"-->
<!--            alt=""-->
<!--            width="600"-->
<!--            height="500"-->
<!--          />-->
<!--          <m-button-->
<!--            v-if="form.webViewLink"-->
<!--            style="margin-top: 20px"-->
<!--            @click="deleteImage"-->
<!--            >Delete image</m-button-->
<!--          >-->
<!--          <m-upload v-else v-model="fileUpload" style="margin-top: 20px">-->
<!--          </m-upload>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; Photo alt &ndash;&gt;-->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Photo alt</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <n-tabs type="card">-->
<!--            <n-tab-pane name="en" tab="Eng">-->
<!--              <m-input :value="form.photoAlt" v-model="form.photoAlt"></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="ru" tab="Rus">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="uk" tab="Ukr">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--          </n-tabs>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; Meta title &ndash;&gt;-->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Meta title</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <n-tabs type="card">-->
<!--            <n-tab-pane name="en" tab="Eng">-->
<!--              <m-input-->
<!--                :value="form.metaTitle"-->
<!--                v-model="form.metaTitle"-->
<!--              ></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="ru" tab="Rus">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="uk" tab="Ukr">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--          </n-tabs>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; Meta description &ndash;&gt;-->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Meta description</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <n-tabs type="card">-->
<!--            <n-tab-pane name="en" tab="Eng">-->
<!--              <m-input-->
<!--                :value="form.metaDescription"-->
<!--                v-model="form.metaDescription"-->
<!--              ></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="ru" tab="Rus">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="uk" tab="Ukr">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--          </n-tabs>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; Meta keywords &ndash;&gt;-->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Meta keywords</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <n-tabs type="card">-->
<!--            <n-tab-pane name="en" tab="Eng">-->
<!--              <m-input-->
<!--                :value="form.metaKeywords"-->
<!--                v-model="form.metaKeywords"-->
<!--              ></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="ru" tab="Rus">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--            <n-tab-pane name="uk" tab="Ukr">-->
<!--              <m-input></m-input>-->
<!--            </n-tab-pane>-->
<!--          </n-tabs>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="block-input">-->
<!--        <div class="col-title">-->
<!--          <span class="subtitle">Is Publish</span>-->
<!--        </div>-->
<!--        <div class="width100">-->
<!--          <n-space>-->
<!--            <n-switch v-model:value="form.isPublish" />-->
<!--          </n-space>-->
<!--        </div>-->
<!--      </div>-->

      <div class="content-block__button">
        <m-button widthButton="101px" class="btn_cancel" @click="cancel">
          <span>Cancel</span>
        </m-button>
        <m-button widthButton="101px" @click="updatePage">
          <span>Save</span>
        </m-button>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "StaticPagesEdit",
  props: {
    id: String,
  },
  components: {
    QuillEditor,
  },
  data: () => {
    return {
      dataProperty: "",
      fileUpload: "",
      keyContent: "1",
      staticPageRes: "",
      form: {
        header: "",
        slug: "",
        content: "",
        // photoAlt: "",
        // metaTitle: "",
        // metaDescription: "",
        // metaKeywords: "",
        isPublish: false,
      },
    };
  },
  mounted() {
    this.fetchStaticPage();
  },
  watch: {
    form() {
      this.keyContent = Date.now();
    },
  },
  methods: {
    updatePage() {
      let formData = new FormData();
      if (this.fileUpload) {
        formData.append("file", this.fileUpload);
      }
      for (let el in this.form) {
        formData.append(`${el}`, this.form[el]);
      }
      const url = `/static-pages/${this.id}`;
      this.$api
        .put(url, formData, {
          headers: {
            "Content-Type": `multipart/form-data;`,
          },
        })
        .then(async () => {
          this.alertShow("success", "Static page create");
        })
        .catch(() => {
          this.alertShow("error", `Static page not create`);
        });
    },
    fetchStaticPage() {
      const url = `/static-pages/${this.id}`;
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.form = data;
        });
    },
    deleteImage() {
      this.form.webViewLink = "";
    },
  },
};
</script>
<style lang=""></style>
