<template>
  <div class="container">
    <div class="header">
      <div class="title">Languages</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Languages</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="create"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="columns"
          :data="list"
          :pagination="{
            pageSize: 10,
          }"
          :single-line="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { h } from "vue";
import { NButton, NTag } from "naive-ui";
export default {
  name: "LanguagesPage",
  data: () => {
    return {
      columns: [
        {
          title: "Id",
          key: "id",
        },
        {
          title: "Locale",
          key: "locale",
        },
        {
          title: "Title",
          key: "title",
        },
        {
          title: "Is default",
          key: "default",
          render(row) {
            const status = row.default;
            let colorBG = "";
            let colorText = "";
            if (status) {
              colorBG = "#EDF6F2";
              colorText = "#4DA97D";
            } else {
              colorBG = "#FEEFF2";
              colorText = "#C04949";
            }
            return h(
              NTag,
              {
                style: {
                  padding: "16px",
                  color: colorText,
                  background: colorBG,
                  fontSize: "14px",
                },
                round: true,
                bordered: false,
              },
              {
                default: () => {
                  return status ? "Yes" : "No";
                },
              }
            );
          },
        },
        {
          title: " Active",
          key: "active",
          render(row) {
            const status = row.active;
            let colorBG = "";
            let colorText = "";
            if (status) {
              colorBG = "#EDF6F2";
              colorText = "#4DA97D";
            } else {
              colorBG = "#FEEFF2";
              colorText = "#C04949";
            }
            return h(
              NTag,
              {
                style: {
                  padding: "16px",
                  color: colorText,
                  background: colorBG,
                  fontSize: "14px",
                },
                round: true,
                bordered: false,
              },
              {
                default: () => {
                  return status ? "Yes" : "No";
                },
              }
            );
          },
        },
        {
          title: "Action",
          key: "actions",
          render() {
            return [
              h(
                NButton,
                {
                  style: {
                    background: "#E2F54B",
                    padding: "4px",
                    width: "32px",
                    height: "32px",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  size: "large",
                  quaternary: true,
                  vertical: false,
                  onClick: () => alert("Button1"),
                },
                {
                  default: () => {
                    return h("img", {
                      src: require("@/assets/icons/button-edit.svg"),
                      alt: "edit",
                    });
                  },
                }
              ),
              h(
                NButton,
                {
                  style: {
                    background: "#0F172A",
                    padding: "4px",
                    width: "32px",
                    height: "32px",
                    "border-radius": "0px 8px 8px 0px",
                    border: "none",
                  },
                  quaternary: true,
                  size: "large",
                  vertical: false,
                  onClick: () => alert("Button3"),
                },
                {
                  default: () => {
                    return h("img", {
                      src: require("@/assets/icons/button-delete.svg"),
                      alt: "delete",
                    });
                  },
                }
              ),
            ];
          },
        },
      ],
      list: [
        {
          id: "1",
          locale: "ukr",
          title: "Ukr",
          default: true,
          active: true,
        },
        {
          id: "2",
          locale: "en",
          title: "Eng",
          default: false,
          active: true,
        },
        {
          id: "3",
          locale: "ru",
          title: "Rus	",
          default: false,
          active: false,
        },
      ],
    };
  },
  methods: {
    create() {
      this.$router.push({ path: "/languages/create" });
    },
  },
};
</script>
<style lang=""></style>
