<template lang="">
  <div class="alert" v-if="alert">
    <n-alert :title="alertTitle" closable :type="alertType" @close="closeAlert">
      {{ alertText }}
    </n-alert>
  </div>
  <div class="container">
    <div class="header">
      <div class="title">Roles</div>
      <div>
        <router-link class="route" to="/">Dashbord</router-link>/
        <router-link to="/" class="disabled-link">Roles</router-link>
      </div>
    </div>
    <div class="block-content reverse">
      <m-button
        :imgUrl="require('@/assets/icons/plus-button.svg')"
        widthButton="148px"
        @click="createRole"
      >
        <span>Create</span>
      </m-button>

      <div class="tab-block">
        <n-data-table
          class="tab"
          :columns="createColumns(openModal, goToRoute, activeCheckboxRole)"
          :data="listRoles"
          :single-line="false"
        />
      </div>
    </div>
  </div>
  <m-modal
    :open="showModalDelete"
    :title="modelTitle"
    :subtitle="modelSubtitle"
    :nameButtonActive="nameButtonActive"
    @cancel-event="closeModel"
    @active-event="deleteRole(deleteId)"
  ></m-modal>
</template>
<script>
import { h } from "vue";
import { NButton, NCheckbox } from "naive-ui";
export default {
  name: "RolesPage",
  data: () => {
    return {
      createColumns: (openModal, goToRoute, activeCheckboxRole) => {
        return [
          {
            title: "ID",
            key: "id",
            className: "textTop",
          },
          {
            title: "Role",
            key: "name",
            className: "textTop",
          },
          {
            title: "Permissions",
            key: "permissions",
            width: "400px",
            render(row) {
              if (row.permissions) {
                return row.permissions.join("; ");
              } else {
                return row.permissions;
              }
            },
          },
          {
            title: "Active",
            key: "isActive",
            render(row) {
              return h(NCheckbox, {
                checked: row.isActive,
                size: "large",
                onClick: () => {
                  row.isActive = !row.isActive;
                  activeCheckboxRole(row);
                },
              });
            },
          },
          {
            title: "Action",
            key: "actions",
            className: "textTop",
            render(row) {
              return [
                h(
                  NButton,
                  {
                    style: {
                      background: "#E2F54B",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "8px 0px 0px 8px",
                    },
                    size: "large",
                    quaternary: true,
                    vertical: false,
                    onClick: () => goToRoute("roles-edit", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-edit.svg"),
                        alt: "edit",
                      });
                    },
                  }
                ),
                // h(
                //   NButton,
                //   {
                //     style: {
                //       background: "#FFFFFF",
                //       padding: "4px",
                //       width: "32px",
                //       height: "32px",
                //       border: "1px solid #CBD5E1",
                //     },
                //     size: "large",
                //     vertical: false,
                //     quaternary: true,
                //     onClick: () => openModal("block"),
                //   },
                //   {
                //     default: () => {
                //       return h("img", {
                //         src: require("@/assets/icons/button-block.svg"),
                //         alt: "block",
                //       });
                //     },
                //   }
                // ),
                h(
                  NButton,
                  {
                    style: {
                      background: "#0F172A",
                      padding: "4px",
                      width: "32px",
                      height: "32px",
                      "border-radius": "0px 8px 8px 0px",
                      border: "none",
                    },
                    quaternary: true,
                    size: "large",
                    vertical: false,
                    onClick: () => openModal("delete", row.id),
                  },
                  {
                    default: () => {
                      return h("img", {
                        src: require("@/assets/icons/button-delete.svg"),
                        alt: "delete",
                      });
                    },
                  }
                ),
              ];
            },
          },
        ];
      },
      listRoles: [],
    };
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    createRole() {
      this.$router.push("/roles/create");
    },
    fetchRoles() {
      const url = "/roles";
      this.$api
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.listRoles = data;
        });
    },
    deleteRole(deleteId) {
      const url = `/roles/${deleteId}`;
      this.$api
        .delete(url)
        .then(() => {
          this.alertShow("success", "Role deleted");
          this.closeModel();
          this.fetchRoles();
        })
        .catch(() => {
          this.alertShow("error", "Role not deleted");
          this.closeModel();
        });
    },
    activeCheckboxRole(obj) {
      const url = `/roles/${obj.id}`;
      this.$api
        .put(url, obj)
        .then(() => {
          this.alertShow("success", "Roles update");
        })
        .catch(() => {
          this.alertShow("error", "Roles not update");
        });
    },
  },
};
</script>
<style lang="scss">
.textTop {
  vertical-align: top;
}
.reverse {
  flex-direction: column-reverse !important;
}
</style>
